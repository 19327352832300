import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../shared/auth/auth-guard.service';
import {HomeComponent} from './home.component';
import {SettingsModule} from '../settings/settings.module';
import {DashboardModule} from '../dashboard/dashboard.module';
import {EntityModule} from '../entity/module/user/entity.module';
import {DistributorModule} from '../entity/module/distributor/distributor.module';
import {TerminalModule} from '../entity/module/terminal/terminal.module';
import {PosModule} from '../entity/module/pos/pos.module';
import {OrderModule} from '../entity/module/order/order.module';
import {VoucherModule} from '../entity/module/voucher/voucher.module';
import {OverviewModule} from '../overview/overview.module';

const homeRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '', redirectTo: '/dashboard', pathMatch: 'full'
      },
      {
        path: 'settings', loadChildren: () => SettingsModule
      },
      {
        path: 'dashboard', loadChildren: () => DashboardModule
      },
      {
        path: 'overview', loadChildren: () => OverviewModule
      },
      {
        path: 'users', loadChildren: () => EntityModule
      },
      {
        path: 'partners', loadChildren: () => DistributorModule
      },
      {
        path: 'points', loadChildren: () => PosModule
      },
      {
        path: 'terminals', loadChildren: () => TerminalModule
      },
      {
        path: 'orders', loadChildren: () => OrderModule
      },
      {
        path: 'vouchers', loadChildren: () => VoucherModule
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(homeRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class HomeRoutingModule {
}
