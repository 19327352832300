export class ColumnConfig {

  show = true;
  type: string;
  minWidth: string;
  width: number;
  showHeader = true;
  sortable = true;

  constructor(columnConfig: Partial<ColumnConfig>) {
    Object.assign(this, columnConfig);

    if (!columnConfig.hasOwnProperty('showHeader')) {
      this.showHeader = true;
    }

    if (!this.type) {
      this.type = 'text';
    }
  }
}
