import {Component, forwardRef, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {EntityService} from '../../module/user/entity.service';
import {OrderItem} from '../../module/order/model/order-item';
import {PackageType} from '../../module/order/model/package-type';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'order-package',
  templateUrl: './order-package.component.html',
  styleUrls: ['./order-package.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrderPackageComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrderPackageComponent),
      multi: true,
    }]
})
export class OrderPackageComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {
  public itemList;
  public constItemList;
  public orderItems = [];
  isError = false;

  @Input()
  public name: string;

  @Input()
  public readonly: boolean;

  @Input()
  public defaultValue: string;

  @Input()
  public translatePrefix = '';

  @Input()
  public posTypeIsSelected = false;

  @Input()
  public newOrderItems;

  constructor(private entityService: EntityService, private http: HttpClient) {
  }

  ngOnInit() {
    const items = this.entityService.getItems(this.name);
    if (items !== null) {
      items.subscribe((response) => {
        if (response.hasOwnProperty('content')) {
          this.itemList = response['content'];
        } else {
          this.itemList = response;
        }
      });
    }
  }

  ngOnChanges(val) {
    if (val.newOrderItems && val.newOrderItems.currentValue.id) {
      this.orderItems = [];
      this.http.get(`api/package-type/pos/${val.newOrderItems.currentValue.id}`).subscribe((res: any[]) => {
        this.itemList = res;
        this.constItemList = res.slice();
      });
    }
  }

  itemListSource() {
    return this.itemList;
  }

  // this is the initial value set to the component
  public writeValue(obj: any) {
    this.orderItems = obj;
  }

  // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  // validates the form, returns null when valid else the validation object
  // in this case we're checking if the json parsing has passed or failed from the onChange method
  public validate(c: FormControl) {
    const error = this.checkPackage(c.value);
    this.isError = error && c.dirty;
    return error;
  }

  checkPackage(packageList) {
    if (packageList === null || packageList.length === 0) {
      return {required: true};
    }

    for (let i = 0; i < packageList.length; i++) {
      const packageModel = packageList[i];
      if (packageModel.quantity <= 0) {
        return {quantityNotPositive: true};
      }

      if (packageModel.packageType.name === 'order.item.empty') {
        return {packageTypeNotSelected: true};
      }
    }

    return null;
  }

  // the method set in registerOnChange to emit changes back to the form
  private propagateChange(data) {
    return data;
  }

  public selectionChange(packageType: any, index): void {
    this.orderItems[index].packageType = packageType;
    this.propagateChange(this.orderItems);
  }

  private addOrderItem() {
    const packageType = new PackageType();
    packageType.name = 'order.item.empty';
    this.orderItems.push(new OrderItem(packageType, 1));
    this.propagateChange(this.orderItems);

    this.orderItems.forEach(elem => {
      const index = this.itemList.indexOf(elem.packageType);
      if (index >= 0) {
        this.itemList.splice(index, 1);
      }
    });
  }

  private removeOrderItem(index) {
    this.orderItems.splice(index, 1);
    this.propagateChange(this.orderItems);
  }

  restOfItems(currentId?) {
    const orderIds = this.orderItems.map(el => el.packageType.id);
    return (this.constItemList || []).filter(el => {
      return !orderIds.includes(el.id) || el.id === currentId;
    });
  }
}
