import {OrderBase} from './order-base';
import {OrderStatus} from './order-status';
import {HasStatus} from '../../../../shared/model/interface/has-status';

export class Order extends OrderBase implements HasStatus {
  public orderStatus: OrderStatus;
  public location: Location;

  constructor(init?: Partial<Order>) {
    super(init);

    this.creationDate = init.creationDate ? new Date(init.creationDate) : null;
    this.dueDate = init.dueDate ? new Date(init.dueDate) : null;
    this.closingDate = init.closingDate ? new Date(init.closingDate) : null;
  }

  getStatus() {
    return this.orderStatus;
  }
}

