import {NgModule} from '@angular/core';
import {EntityService} from '../user/entity.service';
import {EntityModule} from '../user/entity.module';
import {PosRoutingModule} from './pos-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {PosService} from './pos.service';
import {PosQrComponent} from './pos-qr/pos-qr.component';
import {EmailTimeEditComponent} from './email-time-edit/email-time-edit.component';
import {TimePickerModule} from '@progress/kendo-angular-dateinputs';
import {CommonModule} from '@angular/common';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {StatusEditComponent} from './status-edit/status-edit.component';
import {PosFormsService} from './forms/pos.forms-service';

@NgModule({
  imports: [
    CommonModule,
    EntityModule,
    PosRoutingModule,
    TranslateModule,
    TimePickerModule,
    DialogModule
  ],
  declarations: [ PosQrComponent ],
  entryComponents: [ PosQrComponent, EmailTimeEditComponent, StatusEditComponent ],
  providers: [
    PosFormsService,
    {provide: 'APIEndpoint', useValue: 'pos'},
    {provide: EntityService, useClass: PosService},
    {provide: 'title', useValue: 'entity.pos.title'}
  ]
})
export class PosModule {
}
