import {Component, OnInit} from '@angular/core';
import {AuthService} from '../shared/auth/auth.service';
import {PermissionName} from '../shared/model/auth/role-name';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../shared/language.service';
import {Router, RoutesRecognized} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {MonthSelectorComponent} from '../shared/component/month-selector/month-selector.component';
import {HttpClient} from '@angular/common/http';
import {MessageService} from '../shared/message.service';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isEnglish = true;

  version: string;

  userActions = [
    {
      name: 'month.report',
      permissions: [PermissionName.ROLE_SUPER_ADMIN]
    },
    {
      name: 'logout',
      permissions: null
    }
  ];

  menuItems = [
    {
      routerLink: '/dashboard',
      icon: 'dashboard',
      key: 'sidebar.dashboard',
      permissions: [PermissionName.VIEW_DASHBOARD]
    },
    {
      routerLink: '/overview',
      icon: 'home',
      key: 'sidebar.overview',
      permissions: [PermissionName.VIEW_DASHBOARD]
    },
    {
      routerLink: '/orders',
      icon: 'directions_car',
      key: 'entity.order.title',
      permissions: [PermissionName.VIEW_ORDER]
    },
    {
      routerLink: '/partners',
      icon: 'groups',
      key: 'entity.distributor.title',
      permissions: [PermissionName.VIEW_DISTRIBUTOR]
    },
    {
      routerLink: '/points',
      icon: 'store',
      key: 'entity.pos.title',
      permissions: [PermissionName.VIEW_POS]
    },
    {
      routerLink: '/users',
      icon: 'people',
      key: 'entity.user.title',
      permissions: [PermissionName.VIEW_USER]
    },
    {
      routerLink: '/terminals',
      icon: 'kitchen',
      key: 'entity.terminal.title',
      permissions: [PermissionName.VIEW_TERMINAL],
    },
    {
      routerLink: '/vouchers',
      icon: 'receipt',
      key: 'entity.voucher.title',
      permissions: [PermissionName.VIEW_VOUCHER]
    }
  ];

  constructor(public authService: AuthService,
              public languageService: LanguageService,
              private titleService: Title,
              private router: Router,
              private dialogService: DialogService,
              private translateService: TranslateService,
              private http: HttpClient,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.languageService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isEnglish = event.lang === 'en';
      this.switchTitle(this.router.url);
    });

    this.isEnglish = this.languageService.locale === 'en';

    this.switchTitle(this.router.url);
    this.router.events.subscribe((event: any) => {
      if (event instanceof RoutesRecognized) {
        this.switchTitle(event.url);
      }
    });

    this.http.get('/api/version')
      .subscribe((response: any) => {
        this.version = response.version;
      });
  }

  logout() {
    this.authService.logout();
  }

  switchTitle(url: string) {
    const filteredMenuItem = this.menuItems.filter(menuItem => {
      return url.startsWith(menuItem.routerLink);
    });

    if (filteredMenuItem && filteredMenuItem.length === 1) {
      this.languageService
        .translate(filteredMenuItem[0].key)
        .subscribe((translation) => {
          if (filteredMenuItem[0].key !== translation) {
            this.titleService.setTitle('MYCOFFEECUP - ' + translation);
          }
        });
    }
  }

  switchLanguage() {
    const locale = this.isEnglish ? 'en' : 'de';
    this.languageService.switchLanguage(locale)
      .subscribe(() => {
      });
  }

  // TODO: subscribe to login
  getMenuItems() {
    return this.menuItems.filter((menuItem) => {
      return this.authService.hasAnyPermissions(menuItem.permissions);
    });
  }

  getUserActions() {
    return this.userActions.filter((userAction) => {
      return userAction.permissions == null
        || this.authService.hasAnyPermissions(userAction.permissions);
    });
  }

  doAction(action) {
    switch (action.name) {
      case 'logout':
        this.logout();
        break;

      case 'month.report':
        const monthSelectorDialog: DialogRef = this.dialogService.open({
          title: this.translateService.instant('select.month'),
          content: MonthSelectorComponent,
          width: 250
        });

        monthSelectorDialog.result.subscribe((result: any) => {
          if (result.text === 'Submit') {
            this.http.post('api/report/vouchers', {month: result.value})
              .subscribe(() => {
                this.messageService.success();
              });
          }
        });
        break;
    }

  }
}
