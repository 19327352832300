import {Component, Input} from '@angular/core';
import {DialogContentBase, DialogRef} from '@progress/kendo-angular-dialog';

@Component({
  selector: 'email-time-edit',
  styleUrls: ['./email-time-edit.component.scss'],
  templateUrl: './email-time-edit.component.html'
})
export class EmailTimeEditComponent extends DialogContentBase {
  @Input() public items;

  value: Date = null;

  constructor(dialog: DialogRef) {
    super(dialog);
  }

  public onCancelAction(): void {
    this.dialog.close({text: 'Cancel'});
  }

  public onConfirmAction(): void {
    this.dialog.close({text: 'Submit', primary: true});
  }
}
