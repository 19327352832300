import {PackageType} from './package-type';

export class OrderItem {
  private packageType: PackageType;
  private quantity: number;

  constructor(packageType: PackageType, quantity: number) {
    this.packageType = packageType;
    this.quantity = quantity;
  }
}
