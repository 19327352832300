import {Inject, Injectable, Injector} from '@angular/core';
import {OrganizationService} from '../distributor/organization.service';
import {Pos} from './model/pos';
import {PosDTO} from './model/pos-dto';
import {Observable} from 'rxjs/Observable';
import {Distributor} from '../distributor/model/distributor';
import {FormActionName} from '../../../shared/form/form-action-name';
import {FormAction} from '../../../shared/form/form-action';
import {PosQrComponent} from './pos-qr/pos-qr.component';
import {DialogRef} from '@progress/kendo-angular-dialog';
import {FilterData} from '../../../shared/form/filter-data';
import {PointOfSaleBillingDTO} from './model/pos-billing-dto';
import {tap} from 'rxjs/operators/tap';
import {shareReplay} from 'rxjs/internal/operators';
import {PosFormsService} from './forms/pos.forms-service';
import {PointOfSaleDeliveryDTO} from './model/pos-delivery-dto';
import {MonthSelectorComponent} from '../../../shared/component/month-selector/month-selector.component';
import {PermissionName} from '../../../shared/model/auth/role-name';

@Injectable()
export class PosService extends OrganizationService<Pos> {

  public distributors: Observable<Distributor>;
  filterList: any;

  EMPTY_DISTRIBUTOR = {id: null, name: 'filter.distributor.empty'};
  EMPTY_POS_TYPE = {id: null, name: 'filter.posType.empty'};

  INVOICE_TYPES = [
    {id: 0, name: 'INVOICE'}, {id: 1, name: 'DIRECT'}
  ];

  MARKETING_TYPES = [
    {id: 0, name: 'FREE'}, {id: 1, name: 'NORMAL'}, {id: 2, name: 'CUSTOM'}
  ];

  SUBSCRIPTION_TYPES = [
    {id: 0, name: 'YEAR'},
    {id: 1, name: 'MONTH'},
    {id: 2, name: 'SEMIANNUAL'},
  ];

  DISTRIBUTOR_FILTER = {
    type: 'list',
    name: 'distributor',
    key: 'distributorId',
    empty: this.EMPTY_DISTRIBUTOR,
    viewProperty: 'name',
    valueProperty: 'id',
    data: null,
    model: this.EMPTY_DISTRIBUTOR
  };

  constructor(injector: Injector,
              @Inject('APIEndpoint') protected apiEndPoint: string,
              private posFormsService: PosFormsService) {
    super(injector, apiEndPoint);
    this.configureDictionaries();
  }

  private configureDictionaries() {
    // TODO: lazy loading for the filter values list
    this.distributors =
      this.http.get<Distributor>(this.apiPrefix + 'distributor', {params: {'size': '1000', 'showInactive': 'true'}});
    this.DISTRIBUTOR_FILTER.data = this.distributors;
    this.filterList = [];
    this.filterList.push(new FilterData(FilterData.asPartial(this.DISTRIBUTOR_FILTER)));
    this.filterList.push(new FilterData(FilterData.asPartial({
      type: 'boolean',
      value: false,
      key: 'showInactive',
      valueProperty: 'value',
      role: PermissionName.ROLE_SUPER_ADMIN
    })));

    this.items['distributor'] = this.distributors;
    this.items['billingInvoiceType'] = Observable.of(this.INVOICE_TYPES);
    this.items['marketingFeeType'] = Observable.of(this.MARKETING_TYPES);
    this.items['billingSubscriptionType'] = Observable.of(this.SUBSCRIPTION_TYPES);
    this.items['posType'] = this.http.get(`${this.getApiPrefix()}type`);
    this.items['deliveryFeeType'] = this.http.get(`${this.getApiPrefix()}delivery-fee-types`);
    this.items['collectionFeeType'] = this.http.get(`${this.getApiPrefix()}collection-fee-types`);
    this.items['cupServiceType'] = this.http.get(`${this.getApiPrefix()}cup-service-types`);
    this.items['cupPriceType'] = this.http.get(`${this.getApiPrefix()}cup-price-types`);
  }

  convert(response: any): Pos {
    const locations = response.locations;
    response.location = locations && locations.length > 0 ? locations[0] : null;
    const status: string = response.status;
    response.status = this.STATUS.find((distributorStatus) => {
      return distributorStatus.id === status;
    });
    return new Pos(response);
  }

  request(item: Partial<Pos>): any {
    const request = new PosDTO(item);
    if (item.location !== null) {
      request.locations = [];
      request.locations.push(item.location);
    }

    request.status = item.status.id;
    return request;
  }

  listActions(model) {
    const actions = super.listActions(model);
    actions.push(new FormAction({name: FormActionName.ADMINS, key: 'entity.pos.admins'}));

    if (this.isSuperAdmin()) {
      actions.push(new FormAction({name: FormActionName.BILLING, key: 'entity.pos.billing'}));
      actions.push(new FormAction({name: FormActionName.POS_INVOICE, key: 'entity.pos.invoice'}));
    }

    if (model && !model.centralLogistics) {
      actions.push(new FormAction({name: FormActionName.DELIVERY, key: 'entity.pos.delivery'}));
    }

    if ((this.authService.authority.roleInPointOfSale
      && this.authService.authority.roleInPointOfSale.length > 0) && this.apiEndPoint === 'pos') {
      const result = actions.filter(item => {
        if (item.name === 'edit' || item.name === 'delivery') {
          return item;
        }
      });

      return result;
    }

    // actions.push(new FormAction({name: FormActionName.DOWNLOAD_QR, key: 'download.qr.code'}));
    return actions;
  }

  editActions(model, action) {
    if (action.name === FormActionName.ADMINS) {
      return [new FormAction({name: FormActionName.SAVE_ROLES, key: 'entity.action.save'})];
    }

    if (action.name === FormActionName.BILLING) {
      return [new FormAction({name: FormActionName.SAVE_BILLING, key: 'entity.action.save', object: model})];
    }

    if (action.name === FormActionName.DELIVERY) {
      return [new FormAction({name: FormActionName.SAVE_DELIVERY, key: 'entity.action.save', object: model})];
    }

    return super.editActions(model, action);
  }

  doAction(action, object) {
    switch (action.name) {
      case FormActionName.DOWNLOAD_QR:
        return this.onDownloadQR(action, object);
      case FormActionName.BILLING:
        return this.onEditBilling(action, object);
      case FormActionName.SAVE_BILLING:
        return this.onSaveBilling(action, object);
      case FormActionName.DELIVERY:
        return this.onEditDelivery(action, object);
      case FormActionName.SAVE_DELIVERY:
        return this.onSaveDelivery(action, object);
      case FormActionName.POS_INVOICE:
        return this.onPosInvoice(action, object);
    }

    return super.doAction(action, object);
  }

  update(entity: Pos) {
    const requestEntity = this.request(entity);
    const url = `${this.getApiPrefix()}${requestEntity.getId()}${this.isSuperAdmin() ? '/admin' : ''}`;
    return this.http.put<Pos>(url, requestEntity)
      .pipe(tap(() => this.loading = false))
      .pipe(shareReplay());
  }

  getQr(reader, type: string, object, feedbackFunction) {
    const actionResult = this.http
      .get('/api/voucher/pos/' + object.id, {
        responseType: 'blob',
        params: {'type': type}
      });

    actionResult.subscribe((blob: Blob) => {
      reader.addEventListener('load', feedbackFunction, true);
      if (blob) {
        reader.readAsDataURL(blob);
      }
    });
  }

  getApiPrefix() {
    return this.apiPrefix + 'pos/';
  }

  getAddButtonTitle() {
    return 'entity.pos.create.title';
  }

  newInstance(): Pos {
    const distributorFilter = this.filterList.find(filter => filter.name === 'distributor');
    return new Pos({status: this.STATUS[0], distributor: distributorFilter.model, cupConsumer: false});
  }

  layout(model, action?: FormAction) {
    const layout = {
      translatePrefix: 'entity.pos.',
      commandsColumnWidth: 80,
      form: null
    };

    if (!action || action.name === FormActionName.EDIT || action.name === FormActionName.CREATE) {
      layout.form = this.posFormsService.getEditForm();
    } else if (action.name === FormActionName.ADMINS) {
      layout.form = this.posFormsService.getAdminForm();
    } else if (action.name === FormActionName.BILLING) {
      layout.form = this.posFormsService.getBillingForm();
    } else if (action.name === FormActionName.DELIVERY) {
      layout.form = this.posFormsService.getDeliveryForm();
    }

    return layout;
  }

  filters() {
    this.mergeFilters(this.filterList, this.query);
    return this.filterList;
  }

  getRoleItems() {
    return this.http.get(this.apiPrefix + 'pos/' + this.currentEditObject.id + '/user');
  }

  getCurrentDistributor() {
    return this.currentEditObject.distributor;
  }

  getRoleItemRole(userRoleItem) {
    return userRoleItem.pointOfSaleRole;
  }

  saveRoleItems(userRoleItems) {
    this.http.put(this.apiPrefix + 'pos/' + this.currentEditObject.id + '/user', userRoleItems)
      .subscribe(() => {
        this.currentEditObject = undefined;
        this.messageService.success();
      });
  }

  setRoleItemRole(userRoleItem, role) {
    userRoleItem.pointOfSaleRole = role;
  }

  private onDownloadQR(action: any, object: any) {
    const privateReader = new FileReader();
    const publicReader = new FileReader();
    this.getQr(publicReader, 'PUBLIC', object, () => {
      if (object.cupConsumer) {
        this.getQr(privateReader, 'PRIVATE', object, () => {
          this.showQRDialog(object, 550, privateReader.result, publicReader.result);
        });
      } else {
        this.showQRDialog(object, 275, undefined, publicReader.result);
      }
    });
    return null;
  }

  private showQRDialog(object: any, width: number, privateImage: any, publicImage: any) {
    const dialog: DialogRef = this.dialogService.open({
      title: this.translateService.instant('download.qr.code'),
      content: PosQrComponent,
      actions: [{text: this.translateService.instant('entity.action.ok')}],
      width: width
    });
    const posInfo = dialog.content.instance;
    posInfo.imagePrivate = privateImage;
    posInfo.imagePublic = publicImage;
    posInfo.posName = object.name;
  }

  private onPosInvoice(action: any, object: any) {
    const monthSelectorDialog: DialogRef = this.dialogService.open({
      title: this.translateService.instant('select.month'),
      content: MonthSelectorComponent,
      width: 250
    });

    monthSelectorDialog.result.subscribe((result: any) => {
      if (result.text === 'Submit') {
        const reader = new FileReader();
        const actionResult = this.http
          .post(`${this.apiPrefix}report/pos/${object.id}/invoice`,
            {
              month: result.value
            },
            {
              responseType: 'blob'
            });

        actionResult.subscribe((blob: Blob) => {
          reader.addEventListener('load', () => {
            const month = result.value.toLocaleDateString('de-DE', {month: 'long'});
            const downloadLinkElement = <HTMLLinkElement>document.getElementById('download-link');
            const url = window.URL.createObjectURL(blob);
            downloadLinkElement.href = url;
            downloadLinkElement.setAttribute('download', `Rechnung-${object.shortName}-${month}.pdf`);
            downloadLinkElement.click();
            window.URL.revokeObjectURL(url);
          }, true);
          if (blob) {
            reader.readAsDataURL(blob);
          }
        });
      }
    });
    return null;
  }

  private onEditBilling(action: any, object: any) {
    this.http.get(`${this.getApiPrefix()}${object.id}/billing`)
      .subscribe((billingDTO: PointOfSaleBillingDTO) => {
        let pointOfSaleBilling = null;
        if (!billingDTO) {
          pointOfSaleBilling = {
            pointOfSale: {id: object.id},
            marketingFeeType: this.MARKETING_TYPES[0],
            billingInvoiceType: this.INVOICE_TYPES[0],
            billingSubscriptionType: this.SUBSCRIPTION_TYPES[0],
            locations: []
          };
        } else {
          billingDTO.billingInvoiceType
            = this.INVOICE_TYPES.find(i => i.name === billingDTO.billingInvoiceType);

          billingDTO.billingSubscriptionType
            = this.SUBSCRIPTION_TYPES.find(i => i.name === billingDTO.billingSubscriptionType);

          billingDTO.marketingFeeType
            = this.MARKETING_TYPES.find(i => i.name === billingDTO.marketingFeeType);

          pointOfSaleBilling = billingDTO;
        }

        this.entityEdit.emit({
          object: new PointOfSaleBillingDTO(pointOfSaleBilling),
          action: action
        });
      });
    return null;
  }

  private onSaveBilling(action: any, object: any) {
    object.locations = [];
    object.locations.push(object.location);
    object.billingInvoiceType = object.billingInvoiceType.name;
    object.billingSubscriptionType = object.billingSubscriptionType.name;
    object.marketingFeeType = object.marketingFeeType.name;
    return this.http.put(`${this.getApiPrefix()}${object.pointOfSaleId}/billing`, object);
  }

  private onEditDelivery(action: any, object: any) {
    this.http.get(`${this.getApiPrefix()}${object.id}/delivery`)
      .subscribe((deliveryDTO: PointOfSaleDeliveryDTO) => {
        this.entityEdit.emit({
          object: new PointOfSaleDeliveryDTO(
            deliveryDTO ?
              deliveryDTO : {pointOfSale: {id: object.id}, locations: []}),
          action: action
        });
      });
    return null;
  }

  private onSaveDelivery(action: any, object: any) {
    object.locations = [];
    object.locations.push(object.location);
    return this.http.put(`${this.getApiPrefix()}${object.pointOfSaleId}/delivery`, object);
  }
}
