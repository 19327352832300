import {EntityStatus} from '../../../../shared/model/interface/entity-status';
import {VoucherStatusName} from './voucher-status-name';
import {StatusView} from '../../../../shared/model/interface/status-view';

export class VoucherStatus extends EntityStatus {

  public id: string;
  public name: VoucherStatusName;
  public view: StatusView;

  constructor(name: VoucherStatusName) {
    super();
    this.id = name;
    this.name = name;
    switch (name) {
      case VoucherStatusName.ISSUED:
        this.view = new StatusView('kitchen', 'k-notification k-notification-closed');
        break;
      case VoucherStatusName.ASSIGNED:
        this.view = new StatusView('face', 'k-notification k-notification-success');
        break;
      case VoucherStatusName.USED:
        this.view = new StatusView('check', 'k-notification k-notification-success');
        break;
      case VoucherStatusName.DECLINED:
        this.view = new StatusView('warning', 'k-notification k-notification-warning');
        break;
      case VoucherStatusName.PROVIDED:
        this.view = new StatusView('kitchen', 'k-notification k-notification-closed');
        break;
      default:
        this.view = new StatusView('warning', 'k-notification k-notification-closed');
        break;
    }
  }

  getId() {
    return this.id;
  }

  getView() {
    return this.view;
  }
}
