export class EditConfig {

  static DEFAULT_CONFIG = {
    required: false,
    readOnly: false,
    visible: true,
    translate: false,
    translatePrefix: '',
    visibleCheck: null,
    decimals: 2,
    format: 'n2'
  };

  required = false;
  readOnly = false;
  visible = true;
  translate = false;
  translatePrefix = '';

  visibleCheck = null;
  decimals: 2;
  format: 'n2';

  constructor(columnConfig: Partial<EditConfig>) {
    Object.assign(this, EditConfig.DEFAULT_CONFIG, columnConfig);
  }
}
