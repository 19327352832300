import {Injectable} from '@angular/core';
import {DataControl} from '../../../../shared/form/data-control';
import {InputType} from '../../../../shared/input-type';
import {FormControl, Validators} from '@angular/forms';
import {RoleListDataControl} from '../../../../shared/form/role-list-data-control';
import {ListDataControl} from '../../../../shared/form/list-data-control';
import {AuthService} from '../../../../shared/auth/auth.service';
import {PermissionName} from '../../../../shared/model/auth/role-name';

@Injectable()
export class PosFormsService {
  EMPTY_DISTRIBUTOR = {id: null, name: 'filter.distributor.empty'};
  EMPTY_USER = {id: null, name: 'filter.user.empty'};
  EMPTY_POS_TYPE = {id: null, name: 'filter.posType.empty'};

  POS_NAME_VALIDATOR = Validators.compose([
    Validators.required,
    Validators.minLength(4),
    Validators.maxLength(256)
  ]);

  POS_SHORT_NAME_VALIDATOR = Validators.compose([
    Validators.required,
    Validators.minLength(4),
    Validators.maxLength(32)
  ]);

  constructor(private authService: AuthService) {
  }

  isSuperAdmin() {
    return this.authService.hasPermission(PermissionName.ROLE_SUPER_ADMIN);
  }

  getEditForm() {
    const result = [
      new DataControl(
        'id',
        InputType.VIEW,
        new FormControl(),
        {show: false}
      ),
      new ListDataControl(
        'distributor',
        InputType.LIST,
        this.EMPTY_DISTRIBUTOR,
        new FormControl(),
        {type: 'object', viewProperty: 'name'},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: ''}
      ),
      new DataControl(
        'location',
        InputType.LOCATION,
        new FormControl('', Validators.required),
        {show: false},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: ''}
      ),
      new DataControl(
        'name',
        InputType.TEXT,
        new FormControl('', this.POS_NAME_VALIDATOR),
        {show: true, type: 'text', minWidth: '920px'},
        {required: true}
      ),
      new DataControl(
        'shortName',
        InputType.TEXT,
        new FormControl('', this.POS_SHORT_NAME_VALIDATOR),
        {show: true, type: 'text'},
        {required: true}
      ),
      new DataControl(
        'abbreviation',
        InputType.TEXT,
        new FormControl('', Validators.required),
        {show: false}
      ),
      new DataControl(
        'email',
        InputType.TEXT,
        new FormControl('', Validators.email),
        {show: false}
      ),
      new DataControl(
        'phoneNumber',
        InputType.TEXT,
        new FormControl('', Validators.maxLength(32)),
        {minWidth: '920px'}
      ),
      new DataControl(
        'description',
        InputType.AREA,
        new FormControl(),
        {minWidth: '1200px'}
      ),
      new DataControl(
        'businessHours',
        InputType.AREA,
        new FormControl(),
        {minWidth: '1400px'}
      ),
      new DataControl(
        'cupConsumer',
        InputType.BOOLEAN,
        new FormControl(),
        {type: 'boolean', minWidth: '1400px'},
        {readOnly: !this.isSuperAdmin()}
      ),
      new DataControl(
        'logotypeFilePath',
        InputType.IMAGE,
        new FormControl(),
        {show: false}
      ),
      new DataControl(
        'shopPictureFilePath',
        InputType.IMAGE,
        new FormControl(),
        {show: false}
      ),
      new DataControl(
        'status',
        InputType.LIST,
        new FormControl(),
        {type: 'status'},
        {readOnly: false, visible: false, translate: true, translatePrefix: 'entity.status.'}
      ),
      new DataControl(
        'contactPersonName',
        InputType.TEXT,
        new FormControl('', Validators.maxLength(256)),
        {show: false}
      ),
      new DataControl(
        'contactPersonSurname',
        InputType.TEXT,
        new FormControl('', Validators.maxLength(256)),
        {show: false}
      ),
      new DataControl(
        'contactPersonPhone',
        InputType.TEXT,
        new FormControl('', Validators.maxLength(256)),
        {show: false}
      ),
      new DataControl(
        'contactPersonEmail',
        InputType.TEXT,
        new FormControl('', Validators.compose([
          Validators.email,
          Validators.maxLength(256)
        ])),
        {show: false}
      ),
      new DataControl(
        'contactPersonDescription',
        InputType.TEXT,
        new FormControl('', Validators.maxLength(256)),
        {show: false}
      )
    ];
    if (!(this.authService.authority.roleInPointOfSale.length > 0)) {
      result.splice(2, 0, new ListDataControl(
        'posType',
        InputType.LIST,
        this.EMPTY_POS_TYPE,
        new FormControl(),
        {type: 'object', viewProperty: 'name'},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: ''}
      ));
    } else {
      result.splice(1, 1, new ListDataControl(
        'distributor',
        InputType.LIST,
        this.EMPTY_DISTRIBUTOR,
        new FormControl(),
        {type: 'object', viewProperty: 'name'},
        {required: true, readOnly: true, visible: true, translate: true, translatePrefix: ''}
      ));
      result.splice(2, 0, new ListDataControl(
        'posType',
        InputType.LIST,
        this.EMPTY_POS_TYPE,
        new FormControl(),
        {type: 'object', viewProperty: 'name'},
        {required: false, readOnly: true, visible: true, translate: true, translatePrefix: ''}
      ));
    }
    return result;
  }

  getAdminForm() {
    return [
      new RoleListDataControl('userRole',
        InputType.ORGANIZATION_ROLE,
        5,
        this.EMPTY_USER,
        new FormControl(),
        {
          show: true,
          type: 'text'
        })
    ];
  }

  getBillingForm() {
    return [
      new DataControl(
        'pointOfSaleId',
        InputType.VIEW,
        new FormControl(),
        {show: false}
      ),
      new DataControl(
        'location',
        InputType.LOCATION,
        new FormControl('', Validators.required),
        {show: false},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: ''}
      ),
      new DataControl(
        'billingName',
        InputType.TEXT,
        new FormControl('', this.POS_NAME_VALIDATOR),
        {},
        {required: true}
      ),
      new DataControl(
        'vatNumber',
        InputType.NUMERIC,
        new FormControl('', Validators.compose([Validators.min(0), Validators.max(100)])),
        {},
        {required: true}
      ),
      new DataControl(
        'zipCode',
        InputType.TEXT,
        new FormControl(),
        {}
      ),
      new DataControl(
        'accountantEmail',
        InputType.TEXT,
        new FormControl('', Validators.compose([Validators.email, Validators.required])),
        {},
        {required: true}
      ),
      new DataControl(
        'note',
        InputType.AREA,
        new FormControl(),
        {}
      ),
      new ListDataControl(
        'cupPriceType',
        InputType.LIST,
        null,
        new FormControl(),
        {},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: 'cup.price.'}
      ),
      new DataControl(
        'cupPrice',
        InputType.NUMERIC,
        new FormControl('', Validators.compose([Validators.min(0), Validators.max(1000)])),
        {},
        {
          visibleCheck: function (form) {
            const cupPriceType
              = form.controls['cupPriceType'] ? form.controls['cupPriceType'].value : null;
            return cupPriceType != null && cupPriceType.name === 'CUSTOM';
          }
        }
      ),
      new ListDataControl(
        'cupServiceType',
        InputType.LIST,
        null,
        new FormControl(),
        {},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: 'cup.service.'}
      ),
      new DataControl(
        'cupServicePrice',
        InputType.NUMERIC,
        new FormControl('', Validators.compose([Validators.min(0), Validators.max(1000)])),
        {},
        {
          visibleCheck: function (form) {
            const cupServiceType
              = form.controls['cupServiceType'] ? form.controls['cupServiceType'].value : null;
            return cupServiceType != null && cupServiceType.name === 'CUSTOM';
          },
          decimals: 3,
          format: 'n3'
        }
      ),
      new ListDataControl(
        'billingInvoiceType',
        InputType.LIST,
        null,
        new FormControl(),
        {},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: 'invoice.'}
      ),
      new ListDataControl(
        'billingSubscriptionType',
        InputType.LIST,
        null,
        new FormControl(),
        {},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: 'subscription.'}
      ),
      new ListDataControl(
        'deliveryFeeType',
        InputType.LIST,
        null,
        new FormControl(),
        {},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: 'delivery.'}
      ),
      new ListDataControl(
        'collectionFeeType',
        InputType.LIST,
        null,
        new FormControl(),
        {},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: 'collection.'}
      ),
      new DataControl(
        'collectionFee',
        InputType.NUMERIC,
        new FormControl('', Validators.compose([Validators.min(0), Validators.max(100)])),
        {},
        {
          visibleCheck: function (form) {
            const collectionFeeType
              = form.controls['collectionFeeType'] ? form.controls['collectionFeeType'].value : null;
            return collectionFeeType != null && collectionFeeType.name === 'CUSTOM';
          }
        }
      ),
      new ListDataControl(
        'marketingFeeType',
        InputType.LIST,
        null,
        new FormControl(),
        {},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: 'marketing.'}
      ),
      new DataControl(
        'marketingFee',
        InputType.NUMERIC,
        new FormControl('', Validators.compose([Validators.min(0), Validators.max(1000)])),
        {},
        {
          visibleCheck: function (form) {
            const marketingFeeType
              = form.controls['marketingFeeType'] ? form.controls['marketingFeeType'].value : null;
            return marketingFeeType != null && marketingFeeType.name === 'CUSTOM';
          }
        }
      ),
    ];
  }

  getDeliveryForm() {
    return [
      new DataControl(
        'pointOfSaleId',
        InputType.VIEW,
        new FormControl(),
        {show: false}
      ),
      new DataControl(
        'location',
        InputType.LOCATION,
        new FormControl('', Validators.required),
        {show: false},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: ''}
      ),
      new DataControl(
        'zipCode',
        InputType.TEXT,
        new FormControl(),
        {}
      ),
      new DataControl(
        'note',
        InputType.AREA,
        new FormControl(),
        {}
      )
    ];
  }
}
