import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {EmptyComponent} from './empty.component';
import {HomeComponent} from './home.component';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {HomeRoutingModule} from './home-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {CldrIntlService, IntlModule, IntlService} from '@progress/kendo-angular-intl';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {MonthSelectorComponent} from '../shared/component/month-selector/month-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonsModule,
    HomeRoutingModule,
    TranslateModule,
    InputsModule,
    IntlModule,
    DialogModule
  ],
  entryComponents: [
    MonthSelectorComponent
  ],
  declarations: [
    EmptyComponent,
    HomeComponent
  ],
  providers: [
    {provide: IntlService, useExisting: CldrIntlService}
  ]
})
export class HomeModule {
}
