import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthGuard} from '../../../shared/auth/auth-guard.service';
import {EntityService} from '../user/entity.service';
import {TerminalRoutingModule} from './terminal-routing.module';
import {TerminalService} from './terminal.service';
import {EntityModule} from '../user/entity.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    EntityModule,
    TerminalRoutingModule,
    TranslateModule
  ],
  providers: [
    AuthGuard,
    {provide: 'APIEndpoint', useValue: 'terminal'},
    {provide: EntityService, useClass: TerminalService},
    {provide: 'title', useValue: 'entity.terminal.title'}
  ]
})
export class TerminalModule {
}
