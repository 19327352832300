import {Component, forwardRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {EntityService} from '../../module/user/entity.service';
import {UserRole} from './model/user-role';
import {UserStatus} from '../../module/user/model/user-status';
import {UserStatusName} from '../../module/user/model/user-status-name';
import {User} from '../../module/user/model/user';

@Component({
  selector: 'organization-roles',
  templateUrl: './organization-roles.component.html',
  styleUrls: ['./organization-roles.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganizationRolesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrganizationRolesComponent),
      multi: true,
    }]
})
export class OrganizationRolesComponent implements ControlValueAccessor, Validator, OnInit {

  USER_DICTIONARY = 'users';
  ROLES_DICTIONARY = 'roles';

  // roles source
  private rolesList = [];

  // user source
  private userList = [];
  private filteredUserList = [];

  public loading = true;

  // model
  userRoleItems = [];

  userCreationMode = false;
  user = new User({username: '', password: '', status: new UserStatus(UserStatusName.OPERATIVE)});
  editedIndex = -1;

  @Input()
  public max = 1;

  @Input()
  public name: string;

  @Input()
  public readonly: boolean;

  @Input()
  public defaultValue: any;

  public userAddValue: any = {id: -1, name: 'entity.action.user.add'};

  @Input()
  public translatePrefix = '';

  constructor(private entityService: EntityService) {
  }

  ngOnInit() {
    let items = this.entityService.getItems(this.ROLES_DICTIONARY);
    if (items !== null) {
      items.subscribe((roles) => {
        this.rolesList = roles;
        items = this.entityService.getItems(this.USER_DICTIONARY);
        if (items !== null) {
          items.subscribe((users) => {
            this.userList = users;
            if (this.userList.length === 0) {
              this.onAddUser(0);
            }
            this.filterListSource();
            this.loading = false;
          });
        }
      });
    }
  }

  // this is the initial value set to the component
  public writeValue(obj: any) {
    this.userRoleItems = obj === null ? [] : obj;
    if (this.userRoleItems.length === 0) {
      this.addUserRoleItem();
    }
  }

  // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  // validates the form, returns null when valid else the validation object
  // in this case we're checking if the json parsing has passed or failed from the onChange method
  public validate(c: FormControl) {
    return null;
  }

  // the method set in registerOnChange to emit changes back to the form
  private propagateChange(data) {
    return data;
  }

  public selectUser(user: any, index): void {
    this.userRoleItems[index].user = user;
    this.filterListSource();
    this.propagateChange(this.userRoleItems);
  }

  public selectRole(user: any, index): void {
    this.propagateChange(this.userRoleItems);
  }

  filterListSource() {
    this.filteredUserList = this.userList.filter(user => {
      const userRoleItems = this.userRoleItems.filter((userRoleItem) => {
        return userRoleItem.user.id === user.id;
      });

      return userRoleItems.length === 0;
    });
  }

  public onAddUser(index): void {
    this.editedIndex = index;
    this.user = new User({username: '', password: '', status: new UserStatus(UserStatusName.OPERATIVE)});
    this.userCreationMode = true;
  }

  userTitle(item) {
    if (item) {
      return item.username ? item.username : item.name;
    }
    return null;
  }

  private addUserRoleItem() {
    const userRole = new UserRole(this.defaultValue, {id: 0, name: 'ADMIN'});
    this.userRoleItems.push(userRole);
    this.propagateChange(this.userRoleItems);
  }

  private removeUserRoleItem(index) {
    if (this.userRoleItems.length === 1) {
      this.userRoleItems[0].user = this.defaultValue;
    } else {
      this.userRoleItems.splice(index, 1);
    }

    this.propagateChange(this.userRoleItems);
  }

  private cancelUserCreation() {
    this.user = null; // clear form
    this.userCreationMode = false; // close user creation form
    this.editedIndex = -1; // clear index
  }

  private createUser() {
    if ('createUser' in this.entityService) {
      this.entityService['createUser'](this.user)
        .subscribe(user => {
          this.setUser(user);
          this.userList.push(user);
        }, () => {
          this.cancelUserCreation();
        });
    }
  }

  setUser(user) {
    this.user = null; // clear form
    this.userCreationMode = false; // close user creation form
    this.userRoleItems[this.editedIndex].user = user; // set current (where Add clicked) user in the list
    this.editedIndex = -1; // clear index
    this.filterListSource(); // filter user list
    this.propagateChange(this.userRoleItems);
  }
}
