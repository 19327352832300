import {InputType} from '../input-type';
import {FormControl} from '@angular/forms';
import {EditConfig} from './edit-config';
import {ColumnConfig} from './column-config';

export class DataControl {

  public name: string;
  public input: InputType;
  public control: FormControl;
  public columnConfig: ColumnConfig;
  public editConfig: EditConfig;

  constructor(name, input, control, columnConfig, editConfig = {}) {

    this.name = name;
    this.input = input;
    this.control = control;
    this.columnConfig = new ColumnConfig(columnConfig);
    this.editConfig = new EditConfig(editConfig);
  }
}
