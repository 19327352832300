import {Inject, Injectable, Injector} from '@angular/core';
import {Distributor} from './model/distributor';
import 'rxjs/add/observable/of';
import {DistributorDTO} from './model/distributor-dto';
import {OrganizationService} from './organization.service';
import {FormAction} from '../../../shared/form/form-action';
import {FormActionName} from '../../../shared/form/form-action-name';
import {DistributorBillingDTO} from './model/distributor-billing-dto';
import {DistributorDeliveryDTO} from './model/distributor-delivery-dto';
import {DistributorFormsService} from './forms/distributor.forms-service';
import {FilterData} from '../../../shared/form/filter-data';
import {PermissionName} from '../../../shared/model/auth/role-name';

@Injectable()
export class DistributorService extends OrganizationService<Distributor> {

  filterList: any;

  constructor(injector: Injector,
              @Inject('APIEndpoint') protected apiEndPoint: string,
              private distributorFormsService: DistributorFormsService) {
    super(injector, apiEndPoint);
    this.filterList = [];
    this.filterList.push(
      new FilterData(FilterData.asPartial({
        type: 'boolean',
        value: false,
        key: 'showInactive',
        valueProperty: 'value',
        role: PermissionName.ROLE_SUPER_ADMIN
      })));
  }

  convert(response: any): Distributor {
    const locations = response['locations'];
    response.location = locations.length > 0 ? locations[0] : null;
    delete response['locations'];
    response.status = this.STATUS.find((distributorStatus) => {
      return distributorStatus.id === response.status;
    });
    return new Distributor(response);
  }

  request(item): any {
    const request = new DistributorDTO(item);
    if (item.location !== null) {
      request.locations = [];
      request.locations.push(item.location);
    }
    request.status = item.status.id;
    return request;
  }

  filters() {
    this.mergeFilters(this.filterList, this.query);
    return this.filterList;
  }

  newInstance(data): Distributor {
    return data ? new Distributor(data) : new Distributor({status: this.STATUS[0]});
  }

  listActions(model) {
    const actions = super.listActions(model);
    actions.push(new FormAction({name: FormActionName.POSES, key: 'entity.distributor.poses'}));
    actions.push(new FormAction({name: FormActionName.BILLING, key: 'entity.distributor.billing'}));
    actions.push(new FormAction({name: FormActionName.DELIVERY, key: 'entity.distributor.delivery'}));
    if (this.isAdmin()) {
      actions.push(new FormAction({name: FormActionName.ADMINS, key: 'entity.distributor.admins'}));
    }
    return actions;
  }

  editActions(model, action) {
    if (action.name === FormActionName.ADMINS) {
      return [new FormAction({name: FormActionName.SAVE_ROLES, key: 'entity.action.save', object: model})];
    }

    if (action.name === FormActionName.BILLING) {
      return [new FormAction({name: FormActionName.SAVE_BILLING, key: 'entity.action.save', object: model})];
    }

    if (action.name === FormActionName.DELIVERY) {
      return [new FormAction({name: FormActionName.SAVE_DELIVERY, key: 'entity.action.save', object: model})];
    }

    if (action.name === FormActionName.EDIT) {
      if (this.isSuperAdmin()) {
        return [new FormAction({name: FormActionName.SAVE, key: 'entity.action.save'})];
      } else {
        return [];
      }
    }

    return super.editActions(model, action);
  }

  doAction(action, object) {
    const actionResult = super.doAction(action, object);

    switch (action.name) {
      case FormActionName.POSES:
        this.router.navigate(['points'], {relativeTo: this.activatedRoute, queryParams: {distributorId: object.id}});
        return null;
      case FormActionName.DEACTIVATE:
        return this.http.put(`${this.apiPrefix}distributor/${object.id}/deactivate`, null);
      case FormActionName.ACTIVATE:
        return this.http.put(`${this.apiPrefix}distributor/${object.id}/activate`, null);
      case FormActionName.RESTRICT:
        return this.http.put(`${this.apiPrefix}distributor/${object.id}/restrict`, null);
      case FormActionName.BILLING:
        this.http.get(`${this.getApiPrefix()}${object.id}/billing`)
          .subscribe((billingDTO: DistributorBillingDTO) => {
            this.entityEdit.emit({
              object: new DistributorBillingDTO(
                billingDTO ?
                  billingDTO : {distributor: {id: object.id}, locations: []}),
              action: action
            });
          });
        return null;
      case FormActionName.SAVE_BILLING:
        object.locations = [];
        object.locations.push(object.location);
        return this.http.put(`${this.getApiPrefix()}${object.distributorId}/billing`, object);
      case FormActionName.DELIVERY:
        this.http.get(`${this.getApiPrefix()}${object.id}/delivery`)
          .subscribe((deliveryDTO: DistributorDeliveryDTO) => {
            this.entityEdit.emit({
              object: new DistributorDeliveryDTO(
                deliveryDTO ?
                  deliveryDTO : {distributor: {id: object.id}, locations: []}),
              action: action
            });
          });
        return null;
      case FormActionName.SAVE_DELIVERY:
        object.locations = [];
        object.locations.push(object.location);
        return this.http.put(`${this.getApiPrefix()}${object.distributorId}/delivery`, object);
    }

    return actionResult;
  }

  getApiPrefix() {
    return this.apiPrefix + 'distributor/';
  }

  canAdd() {
    return this.isAdmin();
  }

  getAddButtonTitle() {
    return "entity.distributor.create.title";
  }

  private isAdmin() {
    return this.authService.hasPermission('CREATE_DISTRIBUTOR');
  }

  layout(model, action?: FormAction) {
    const layout = {
      translatePrefix: 'entity.distributor.',
      commandsColumnWidth: 80,
      form: null
    };

    if (!action || action.name === FormActionName.EDIT || action.name === FormActionName.CREATE) {
      layout.form = this.distributorFormsService.getEditForm();
    } else if (action.name === FormActionName.ADMINS) {
      layout.form = this.distributorFormsService.getAdminForm();
    } else if (action.name === FormActionName.BILLING) {
      layout.form = this.distributorFormsService.getBillingForm();
    } else if (action.name === FormActionName.DELIVERY) {
      layout.form = this.distributorFormsService.getDeliveryForm();
    }

    return layout;
  }

  getRoleItems() {
    return this.http.get(this.apiPrefix + 'distributor/' + this.getCurrentDistributor().id + '/user');
  }

  getCurrentDistributor() {
    return this.currentEditObject;
  }

  getRoleItemRole(userRoleItem) {
    return userRoleItem.distributorRole;
  }

  saveRoleItems(userRoleItems) {
    this.http.put(this.apiPrefix + 'distributor/' + this.getCurrentDistributor().id + '/user', userRoleItems)
      .subscribe(() => {
        this.currentEditObject = undefined;
        this.messageService.success();
      });
  }

  setRoleItemRole(userRoleItem, role) {
    userRoleItem.distributorRole = role;
  }
}
