import {EventEmitter, Injectable, Injector} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {map} from 'rxjs/operators/map';
import {tap} from 'rxjs/operators/tap';
import {shareReplay} from 'rxjs/internal/operators';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {HasId} from './model/interface/has-id';
import {EntityChangeEvent} from './model/entity-change-event';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FormAction} from './form/form-action';
import {Observable} from 'rxjs/Rx';
import {FormActionName} from './form/form-action-name';
import {PermissionName} from './model/auth/role-name';
import {AuthService} from './auth/auth.service';
import {FilterData} from './form/filter-data';

interface APIEndpoint {
  api(): string;
}

@Injectable()
export abstract class InstancePageableService<T extends HasId> extends BehaviorSubject<GridDataResult> implements APIEndpoint {

  public apiPrefix = 'api/';
  public loading: boolean;
  protected http: HttpClient;
  public authService: AuthService;
  protected activatedRoute: ActivatedRoute;
  protected router: Router;

  public STATUS_ACTION_MAP = {
    'INOPERATIVE': 'deactivate',
    'OPERATIVE': 'activate',
    'RESTRICTED': 'restrict',
  };

  query: any = null;

  // TODO Type!
  public entityEdit: EventEmitter<any> = new EventEmitter();
  public entityCreate: EventEmitter<any> = new EventEmitter();
  public entitySave: EventEmitter<any> = new EventEmitter();
  public entityFilter: EventEmitter<any> = new EventEmitter();

  constructor(injector: Injector) {
    super(null);
    this.http = injector.get(HttpClient);
    this.authService = injector.get(AuthService);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.activatedRoute.queryParams.subscribe(params => this.query = params);

  }

  getById(id: number | string) {
    return this.http.get<T>(this.apiPrefix + this.api() + '/' + id).pipe(shareReplay());
  }

  create(entity: T) {
    const requestEntity = this.request(entity);
    return this.http.post(this.apiPrefix + this.api(), requestEntity).pipe(shareReplay());
  }

  read(state: any): void {
    this.loading = true;
    this.http
      .get(this.apiPrefix + this.api(), {params: this.params(state)})
      .pipe(
        map(response => {
            const items = [];
            if (response['content'].length > 0) {
              response['content'].forEach((item) => {
                items.push(this.convert(item));
              });
            }
            return (<GridDataResult>{
              data: items,
              total: response['totalElements']
            });
          }
        ),
        tap(() => this.loading = false)
      ).subscribe(x => super.next(x));
  }

  update(entity: T) {
    const requestEntity = this.request(entity);
    return this.http.put<T>(this.apiPrefix + this.api() + '/' + requestEntity.getId(), requestEntity)
      .pipe(tap(() => this.loading = false))
      .pipe(shareReplay());
  }

  delete(entity: T) {
    this.loading = true;
    const requestEntity = this.request(entity);
    return this.http.delete(this.apiPrefix + this.api() + '/' + requestEntity.getId())
      .pipe(tap(() => this.loading = false))
      .pipe(shareReplay());
  }

  public params(state: any): HttpParams {
    let params = new HttpParams();
    if (state.sort && state.sort.length !== 0 && state.sort[0].dir) {
      const sort = state.sort[0].field + ',' + state.sort[0].dir;
      params = params.set('sort', sort);
    }
    params = params.set('page', String(Math.round(state.skip / state.take)))
      .set('size', state.take as string);
    if (this.query) {
      Object.keys(this.query).forEach((key) => {
        params = params.set(key, this.query[key]);
      });
    }

    if (params.get('sort') !== null) {
      this.layout().form.forEach(dataControl => {
        if (dataControl.columnConfig.type === 'object'
          && dataControl.columnConfig.viewProperty
          && params.get('sort').startsWith(dataControl.name)) {
          const direction = params.get('sort').split(',')[1];
          params = params.set('sort', dataControl.name + '.' + dataControl.columnConfig.viewProperty + ',' + direction);
        }
      });
    }

    return params;
  }

  setQuery(query) {
    this.query = query;
    this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: query});
  }

  abstract api(): string;

  abstract convert(entity: any): T;

  abstract request(entity: Partial<T>): any;

  // TODO param internal information avoid null, null
  layout(entity: T = null, actionName: FormAction = null) {
    return null;
  }

  abstract newInstance(data: any): T;

  onChange(event: EntityChangeEvent, form: FormGroup) {
  }

  getImages(propertyName: string): any {
    return null;
  }

  getItems(propertyName: string): any {
    return null;
  }

  filters() {
    return null;
  }

  protected isSuperAdmin() {
    return this.authService.hasPermission(PermissionName.ROLE_SUPER_ADMIN);
  }

  protected mergeFilters(filters, query) {
    filters.forEach((filter) => {
      if (query && query.hasOwnProperty(filter.key)) {
        if (filter.type === 'boolean') {
          filter.value = query[filter.key] === 'true';
        } else {
          filter.value = query[filter.key];
        }
      }
    });
  }

  statistic() {
    return null;
  }

  listActions(model) {
    return [
      new FormAction({name: FormActionName.EDIT, key: 'entity.action.edit'})
    ];
  }

  // TODO: move to actions
  canAdd() {
    return true;
  }

  getAddButtonTitle() {
    return "entity.action.add";
  }
  
  editActions(model: T, formAction: FormAction): FormAction[] {
    return [new FormAction({name: FormActionName.SAVE, key: 'entity.action.save'})];
  }

  doAction(action: FormAction, object): Observable<Object> {
    switch (action.name) {
      case FormActionName.SAVE:
        const request = object.id ? this.update(object) : this.create(object);
        request.subscribe(() => {
          this.entitySave.emit({object: object, action: action});
        });
        return request;

      case FormActionName.EDIT:
        this.entityEdit.emit({object: object, action: action});
        break;

      case FormActionName.CREATE:
        this.entityCreate.emit({object: object, action: action});
        break;
    }

    return null;
  }

}
