import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {GridModule} from '@progress/kendo-angular-grid';
import {DialogModule} from '@progress/kendo-angular-dialog';

import {ReactiveFormsModule} from '@angular/forms';
import {EntityService} from '../user/entity.service';
import {DistributorService} from './distributor.service';
import {DistributorRoutingModule} from './distributor-routing.module';
import {EntityModule} from '../user/entity.module';
import {EmailTimeEditComponent} from '../pos/email-time-edit/email-time-edit.component';
import {TimePickerModule} from '@progress/kendo-angular-dateinputs';
import {TranslateModule} from '@ngx-translate/core';
import {StatusEditComponent} from '../pos/status-edit/status-edit.component';
import {DistributorFormsService} from './forms/distributor.forms-service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonsModule,
    GridModule,
    DialogModule,
    EntityModule,
    DistributorRoutingModule,
    TranslateModule,
    TimePickerModule
  ],
  declarations: [],
  entryComponents: [EmailTimeEditComponent, StatusEditComponent],
  providers: [
    DistributorFormsService,
    {provide: 'APIEndpoint', useValue: 'distributor'},
    {provide: EntityService, useClass: DistributorService},
    {provide: 'title', useValue: 'entity.distributor.title'}
  ]
})
export class DistributorModule {
}
