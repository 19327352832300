import {Location} from '../../../../shared/model/location';
import {Cloneable} from '../../../../shared/model/interface/cloneable';

export class PointOfSaleDeliveryDTO extends Cloneable<PointOfSaleDeliveryDTO> {

  pointOfSaleId: string;
  pointOfSale: any;
  distributor: any;
  zipCode: string;
  note: string;
  public locations: Location[];
  public location: Location;

  constructor(init?: Partial<PointOfSaleDeliveryDTO>) {
    super(init);
    this.location = init.locations.length > 0 ? init.locations[0] : null;
    this.pointOfSaleId = init.pointOfSale.id;
  }

}
