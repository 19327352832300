import {Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {DashboardLocation} from '../../shared/model/dashboard-location';

declare var google;

@Component({
  selector: 'dashboard-location',
  templateUrl: './dashboard-location.component.html',
  styleUrls: ['./dashboard-location.component.scss']
})
export class DashboardLocationComponent implements OnChanges, OnInit {
  map: any;
  markers: any[] = [];
  imageFolder = '/assets/images';
  image: any = {
    url: '/assets/images/pin-b.png',
    size: new google.maps.Size(40, 60),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(20, 60),
    scaledSize: new google.maps.Size(40, 60)
  };

  vienna = {lat: 48.2086, lng: 16.3836};

  @ViewChild('gmap') gmapElement: any;

  @Input()
  locations: DashboardLocation[];

  @Output() locationSelection: EventEmitter<any> = new EventEmitter();

  constructor(private zone: NgZone) {
  }

  ngOnInit() {
    this.initMap();
  }

  ngOnChanges() {
    this.showMarkers();
  }

  private initMap() {
    this.map = new google.maps.Map(this.gmapElement.nativeElement, {
      zoom: 12,
      center: this.vienna
    });
  }

  showMarkers() {
    this.deleteMarkers();
    this.locations.forEach((location) => {
      this.image.url = this.imageFolder + '/pin-' + location.type + '.png';
      this.addMarker(location, this.image);
    });
  }

  private selectLocation(event) {
    event.stop();
  }

  private addMarker(location, customImage) {
    const marker = new google.maps.Marker({
      position: location.getLocation(),
      clickable: true,
      crossOnDrag: false,
      optimized: false,
      map: this.map,
      icon: customImage
    });
    this.markers.push(marker);
    google.maps.event.addListener(marker, 'click',
      (event) => this.zone.run(() => this.selectLocation(event)));
  }

  private setMapOnAll(map) {
    this.markers.forEach((m) => m.setMap(map));
  }

  private clearMarkers() {
    this.setMapOnAll(null);
  }

  private deleteMarkers() {
    this.clearMarkers();
    this.markers = [];
  }
}
