import {OrderBase} from './order-base';
import {Order} from './order';

export class OrderDTO extends OrderBase {
  public locations: Location[];
  public orderStatus: string;

  constructor(init?: Partial<Order>) {
    super(init);
  }
}

