import {Component, OnInit} from '@angular/core';
import {AuthService} from '../shared/auth/auth.service';
import {Observable} from 'rxjs/Observable';
import {User} from '../entity/module/user/model/user';

@Component({
  template: `
    <h2 translate="settings.title"></h2>
    <div *ngIf="user$ | async as user">
      <h3>{{ user.name }}</h3>
      <div>
        <label translate="entity.user.id">Id: </label>{{ user.id }}
      </div>
    </div>
  `
})
export class SettingsComponent implements OnInit {
  user$: Observable<User>;

  constructor(public authService: AuthService) {

  }

  ngOnInit() {
    this.user$ = this.authService.getCurrentUser();
  }
}
