import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {EntityService} from '../module/user/entity.service';
import {InputType} from '../../shared/input-type';
import {FormAction} from '../../shared/form/form-action';
import {FormActionName} from '../../shared/form/form-action-name';
import smoothscroll from 'smoothscroll-polyfill';
import {MessageService} from '../../shared/message.service';
import {ServerMessage} from '../../shared/model/message/server-message';

@Component({
  selector: 'entity-edit-form',
  styleUrls: ['./entity-edit-form.component.scss'],
  templateUrl: './entity-edit-form.component.html'
})

export class EntityEditFormComponent implements OnInit {
  keys = Object.keys;

  public inputType = InputType;
  protected layout: any;
  protected data: any;

  protected actions = [];
  protected actionEvent: FormAction;

  public editForm: FormGroup = new FormGroup({});

  @Input()
  public active = false;

  @Input()
  public translatePrefix = '';

  @Input()
  public set model(event: any) {
    if (event) {
      this.data = event.object;
      this.actionEvent = event.action;
      this.startForm();
      this.resetForm();
    } else {
      this.data = undefined;
    }

    this.active = this.data !== undefined;
  }

  public get model() {
    return this.data;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<any> = new EventEmitter();

  constructor(private entityService: EntityService,
              private messageService: MessageService) {
    smoothscroll.polyfill();
  }

  ngOnInit() {
  }

  protected resetForm() {
    this.editForm.reset(this.data);
  }

  protected startForm() {
    this.editForm = new FormGroup({});
    this.layout = this.entityService.layout(this.data, this.actionEvent);
    this.layout.form.forEach((formControl) => {
      this.editForm.addControl(formControl.name, formControl.control);
    });

    if (this.entityService.apiEndPoint === 'order' &&
      (this.entityService.authService.authority.roleInPointOfSale
        && this.entityService.authService.authority.roleInPointOfSale.length > 0) ||
      this.entityService.apiEndPoint === 'order' && (this.entityService.authService.authority.roleInDistributor
        && this.entityService.authService.authority.roleInDistributor.length > 0)) {
      this.layout.form = this.layout.form.filter(item => {
        if (item.name !== 'location'
          && item.name !== 'description'
          && item.name !== 'dueDate') {
          return item;
        }
      });

      if (this.actionEvent.name !== 'create') {
        this.actions = [];
        this.actions.push(new FormAction({name: FormActionName.CANCEL, key: 'entity.action.cancel'}));
      } else {
        this.actions = this.entityService.editActions(this.data, this.actionEvent);
        this.actions.push(new FormAction({name: FormActionName.CANCEL, key: 'entity.action.cancel'}));
      }

    } else {
      this.actions = this.entityService.editActions(this.data, this.actionEvent);
      this.actions.push(new FormAction({name: FormActionName.CANCEL, key: 'entity.action.cancel'}));
    }
  }

  isNew() {
    return !this.data.id;
  }

  title() {
    if (this.actionEvent && this.actionEvent.name === FormActionName.ADMINS) {
      return 'manage.admins';
    }

    if (this.actionEvent && this.actionEvent.name === FormActionName.BILLING) {
      return 'manage.billing';
    }

    if (this.actionEvent && this.actionEvent.name === FormActionName.DELIVERY) {
      return 'manage.delivery';
    }

    return this.isNew() ? 'create.title' : 'edit.title';
  }

  public doAction(action) {
    if (action.name !== FormActionName.CANCEL) {
      Object.keys(this.editForm.controls).forEach(controlName => {
        this.editForm.controls[controlName].markAsDirty();
        this.editForm.controls[controlName].updateValueAndValidity();
      });

      const element = document.querySelector('.form-group .ng-invalid');
      if (element && !this.editForm.valid) {
        element.parentElement.scrollIntoView({behavior: 'smooth'});
        this.messageService.warning(new ServerMessage({
          message: 'form.validation.warning'
        }));
        return;
      }
    }


    this.action.emit({action: action, entity: this.editForm.value});
  }

  changeHandler(changeItem) {
    this.entityService.onChange(changeItem, this.editForm);
  }

  closeForm(): void {
    this.active = false;
    this.cancel.emit();
  }

  isPrimaryAction(action: FormAction) {
    switch (action.name) {
      case 'save':
        return true;
      default:
        return false;
    }
  }

  get getPosTypeIsSelected() {
    return !!this.layout.form.find(el => el.name === 'pointOfSale').control.value.id;
  }

  get getPointOfSale() {
    return this.layout.form.find(el => el.name === 'pointOfSale').control.value;
  }
}
