import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {Terminal} from '../../module/terminal/model/terminal';
import {TerminalService} from '../../module/terminal/terminal.service';

@Component({
  selector: 'mcc-terminal-details',
  templateUrl: './terminal-details.component.html',
  styleUrls: ['./terminal-details.component.scss']
})
export class TerminalDetailsComponent implements OnInit {

  public terminal: Terminal;
    public editForm: FormGroup = new FormGroup({});
    public loading = true;


      constructor(private route: ActivatedRoute,
        private terminalService: TerminalService) {
      }

      ngOnInit() {
        this.loading = true;
        this.route.paramMap.subscribe(params => {
          this.terminalService.getById(params.get('terminalId'))
            .subscribe((response) => {
          this.editForm.addControl('location', new FormControl());
          this.terminal = this.terminalService.convert(response) as Terminal;
          this.editForm.reset(this.terminal);

          this.loading = false;
        });
    });
  }
}
