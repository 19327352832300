import {EventEmitter, Injectable} from '@angular/core';
import {ServerMessage} from './model/message/server-message';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class MessageService {
  // messages: ServerMessage[] = [];
  public messageAdded: EventEmitter<ServerMessage> = new EventEmitter();
  public messageCleared: EventEmitter<void> = new EventEmitter();
  public messagePopup: EventEmitter<any> = new EventEmitter();

  constructor(private translate: TranslateService) {
  }

  warning(message: ServerMessage) {
    message.className = 'k-notification-warning';
    this.add(message);
  }

  error(message: ServerMessage) {
    message.className = 'k-notification-error';
    this.add(message);
  }

  success(message?: ServerMessage) {
    if (!message) {
      this.translate.get('entity.action.message.success')
        .subscribe(translation => {
          this.success(new ServerMessage({message: translation}));
        });
    } else {
      message.className = 'k-notification-success';
      this.add(message);
    }
  }

  popup(data: any) {
    this.messagePopup.emit(data);
  }

  add(message: ServerMessage) {
    // this.messages.push(new ServerMessage(message));
    this.messageAdded.emit(new ServerMessage(message));
  }

  clear() {
    // this.messages = [];
    this.messageCleared.emit();
  }

  get getCurrentYear() {
    return new Date().getFullYear();
  }
}
