import {VoucherStatus} from './voucher-status';
import {VoucherBase} from './voucher-base';
import {HasStatus} from '../../../../shared/model/interface/has-status';

export class Voucher extends VoucherBase implements HasStatus {
  public status: VoucherStatus;

  constructor(init?: Partial<Voucher>) {
    super(init);
  }

  getStatus() {
    return this.status;
  }

}


