export enum InputType {
  HEALTH = 'health',
  ROLES = 'roles',
  ORDER_PACKAGE = 'orderPackage',
  ORGANIZATION_ROLE = 'userRole',
  USER_ROLE = 'userRole',
  TEXT = 'text',
  NUMERIC = 'numeric',
  AREA = 'area',
  LOCATION = 'location',
  IMAGE = 'image',
  LIST = 'list',
  VIEW = 'view',
  PASSWORD = 'password',
  BOOLEAN = 'boolean',
  DATE = 'date'
}
