import {Component, Input} from '@angular/core';
import {HasStatus} from '../../../shared/model/interface/has-status';

@Component({
  selector: 'status-view',
  templateUrl: 'status-view.component.html',
  styleUrls: ['status-view.component.scss']
})
export class StatusViewComponent {

  @Input()
  public status: HasStatus;

}
