import {Component, EventEmitter, forwardRef, Input, NgZone, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {Location} from '../../../shared/model/location';

declare var google;

@Component({
  selector: 'mcc-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LocationComponent),
      multi: true,
    }]
})
export class LocationComponent implements ControlValueAccessor, Validator {
  private parseError: boolean;
  map: any;
  markers: any[] = [];
  image: any = {
    url: '/assets/images/pin-b.png',
    size: new google.maps.Size(40, 60),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(20, 60),
    scaledSize: new google.maps.Size(40, 60)
  };

  vienna = {lat: 48.2086, lng: 16.3836};
  service: any;
  autocomplete: any;
  places: any;
  placeQuery = '';
  moveAutoCompleteAttempt = 0;

  @ViewChild('gmap') gmapElement: any;
  @ViewChild('place') addressElement: any;

  location: Location;

  @Input()
  public name: string;

  @Input()
  public readonly: boolean;

  @Input()
  public required: boolean;

  @Input()
  public translatePrefix = '';

  @Output() placeChange: EventEmitter<any> = new EventEmitter();

  constructor(private zone: NgZone) {
  }

  moveAutoComplete() {
    const placeAutoCompleteList = document.getElementsByClassName('pac-container');
    if (placeAutoCompleteList.length > 0) {

      for (let i = 0; i < placeAutoCompleteList.length; i++) {
        const placeAutoCompleteElement = placeAutoCompleteList[i];

        const addressContainer = document.getElementById('place-container');
        if (addressContainer !== null) {
          addressContainer.appendChild(placeAutoCompleteElement);
        }
      }

    } else if (this.moveAutoCompleteAttempt < 5) {
      this.moveAutoCompleteAttempt++;
      setTimeout(() => this.moveAutoComplete(), 250);
    }
  }

  // this is the initial value set to the component
  public writeValue(obj: any) {
    this.location = obj !== null ? new Location(obj) : obj;
    this.initMap(this.location);
  }

  // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  // validates the form, returns null when valid else the validation object
  // in this case we're checking if the json parsing has passed or failed from the onChange method
  public validate(c: FormControl) {
    if (this.required
      && (!c.value || !c.value.address || !c.value.longitude || !c.value.latitude)) {
      return {required: true};
    }

    if (this.parseError) {
      return {mapError: {valid: false}};
    }

    return null;
  }

  // the method set in registerOnChange to emit changes back to the form
  private propagateChange(data) {
    return data;
  }

  public addressChanged(address) {
    this.location.address = address;
    this.propagateChange(this.location);
  }

  private initMap(location: Location) {
    this.map = new google.maps.Map(this.gmapElement.nativeElement, {
      zoom: 12,
      center: location && location.getLocation() ? location.getLocation() : this.vienna
    });

    this.service = new google.maps.places.PlacesService(this.map);
    google.maps.event.addListener(this.map, 'click',
      (e) => this.zone.run(() => this.updateLocation(e)));

    const query = {componentRestrictions: null};
    // query.types = ['address'];
    query.componentRestrictions = {country: 'AT'};
    this.autocomplete = new google.maps.places.Autocomplete(this.addressElement.nativeElement, query);
    this.autocomplete.addListener('place_changed', () => this.zone.run(() => this.updateAddress()));

    setTimeout(() => this.moveAutoComplete(), 250);

    if (location === null) {
      this.location = new Location();
    } else if (location.getLocation() !== null) {
      this.addMarker(location, this.image);
    }
  }

  private updateAddress() {
    if (this.readonly) {
      return;
    }

    const event = this.autocomplete.getPlace();
    this.deleteMarkers();
    this.location.longitude = event.geometry.location.lng();
    this.location.latitude = event.geometry.location.lat();
    this.map.panTo(event.geometry.location);
    this.addMarker(this.location, this.image);
    if (event.place_id) {
      this.showPlaceDetails(event.place_id);
    }
    this.propagateChange(this.location);
  }

  private updateLocation(event) {
    if (this.readonly) {
      return;
    }

    this.deleteMarkers();
    this.location.longitude = event.latLng.lng();
    this.location.latitude = event.latLng.lat();
    this.addMarker(this.location, this.image);
    if (event.placeId) {
      this.showPlaceDetails(event.placeId);
    }
    event.stop();
    this.propagateChange(this.location);
  }

  private showPlaceDetails(placeId) {
    this.service.getDetails({
      placeId: placeId,
      language: 'de'
    }, (place, status) => this.zone.run(() => this.updatePlace(place, status)));
  }

  private updatePlace(place, status) {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      this.location.address = place.formatted_address;
      this.propagateChange(this.location);
      this.placeChange.emit(place);
    }
  }

  private addMarker(location, customImage) {
    const marker = new google.maps.Marker({
      position: location.getLocation(),
      clickable: !this.readonly,
      draggable: !this.readonly,
      crossOnDrag: false,
      optimized: false,
      map: this.map,
      icon: customImage
    });
    this.markers.push(marker);
    google.maps.event.addListener(marker, 'dragend',
      (event) => this.zone.run(() => this.handleDragEnd(event)));
  }

  private handleDragEnd(event) {
    if (this.readonly) {
      return;
    }
    this.location.longitude = event.latLng.lng();
    this.location.latitude = event.latLng.lat();
    this.propagateChange(this.location);
  }

  private setMapOnAll(map) {
    this.markers.forEach((m) => m.setMap(map));
  }

  private clearMarkers() {
    this.setMapOnAll(null);
  }

  private deleteMarkers() {
    this.clearMarkers();
    this.markers = [];
  }
}
