import {HasId} from '../../../../shared/model/interface/has-id';
import {Cloneable} from '../../../../shared/model/interface/cloneable';
import {Distributor} from '../../distributor/model/distributor';

export class PosBase extends Cloneable<PosBase> implements HasId {

  public id: string;
  public name: string;
  public shortName: string;
  public email: string;
  public phoneNumber: string;
  public description: string;
  public businessHours: string;
  public logotypeFilePath: string;
  public shopPictureFilePath: string;
  public distributor: Distributor;
  public contactPersonName: string;
  public contactPersonSurname: string;
  public contactPersonPhone: string;
  public contactPersonEmail: string;
  public contactPersonDescription: string;
  public cupConsumer: boolean;

  constructor(init?: Partial<PosBase>) {
    super(init);
  }

  public getId() {
    return this.id;
  }
}

