import {HasId} from './interface/has-id';
import {Cloneable} from './interface/cloneable';

export class Location extends Cloneable<Location> implements HasId {
  id?: number;
  public longitude: number;
  public latitude: number;
  address: string;

  constructor(init?: Partial<Location>) {
    super(init);
  }

  public getId(): number {
    return this.id;
  }

  public getLocation() {
    if (this.latitude !== null && this.longitude !== null) {
      return {lat: this.latitude, lng: this.longitude};
    }

    return null;
  }
}
