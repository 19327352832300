import {HasId} from '../../../../shared/model/interface/has-id';
import {Cloneable} from '../../../../shared/model/interface/cloneable';

export class UserDTO extends Cloneable<UserDTO> implements HasId {
  public id?: number;
  public username?: string;
  public name?: string;
  public surname?: string;
  public email?: string;
  public roles?: string[];
  public status?: string;
  public password?: string;
  public gender?: string;

  constructor(init?: Partial<UserDTO>) {
    super(init);
  }

  public getId(): number {
    return this.id;
  }
}

