import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {PermissionName} from '../shared/model/auth/role-name';
import {AuthService} from '../shared/auth/auth.service';

@Component({
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  loading = true;
  isMonthMode = true;
  showDownloadBtns = true;
  EMPTY_DISTRIBUTOR = {id: null, name: 'filter.distributor.all'};
  EMPTY_POINT_OF_SALE = {id: null, name: 'filter.pos.all'};
  distributor: any = this.EMPTY_DISTRIBUTOR;
  pointOfSale: any = this.EMPTY_POINT_OF_SALE;
  distributors: Array<any> = [this.EMPTY_DISTRIBUTOR];
  pointOfSalesSource: Array<any> = [this.EMPTY_POINT_OF_SALE];
  pointOfSales: Array<any> = [this.EMPTY_POINT_OF_SALE];

  overviewStatistics = {
    cupsOrderedTotal: 0,
    cupsReturnedTotal: 0,
    vouchersReceivedTotal: 0,
    vouchersIssuedTotal: 0
  };

  public date: Date = new Date();
  // max = new Date(2025, 1, 1);
  max = new Date();
  min = new Date(2019, 1, 1);

  constructor(private http: HttpClient,
              public translate: TranslateService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.max.setDate(this.max.getDate() + 1);
    this.http.get('/api/distributor', {params: {'size': '1000'}})
      .subscribe((distributors: any) => {
        this.distributors = this.distributors.concat(distributors.content);

        this.http.get('/api/pos', {params: {'size': '1000'}})
          .subscribe((pointOfSales: any) => {
            this.pointOfSalesSource = this.pointOfSales.concat(pointOfSales.content);
            this.pointOfSales = this.pointOfSalesSource;

            this.updateView();
          });
      });


    if ((this.authService.authority.roleInPointOfSale
        && this.authService.authority.roleInPointOfSale.length > 0) ||
      (this.authService.authority.roleInDistributor
        && this.authService.authority.roleInDistributor.length > 0)) {
        this.showDownloadBtns = false;
    }
  }

  handleDateChange(dateModel) {
    if (dateModel.valid) {
      this.updateView();
    }
  }

  handleSwitchChange() {
    if (this.date < this.max && this.date > this.min) {
      this.updateView();
    }
  }

  handleDistributorChange() {
    if (this.distributor.id) {
      this.pointOfSales = this.pointOfSalesSource.filter((pointOfSale) => {
        return pointOfSale.distributor && (pointOfSale.distributor.id === this.distributor.id);
      });
      this.pointOfSales.unshift(this.EMPTY_POINT_OF_SALE);
    } else {
      this.pointOfSales = this.pointOfSalesSource;
    }

    this.pointOfSale = this.EMPTY_POINT_OF_SALE;

    this.updateView();
  }

  updateView() {
    this.loading = true;
    const params = {
      interval: this.isMonthMode ? 'MONTH' : 'YEAR',
      date: this.date.toISOString()
    };
    let request;

    if (this.pointOfSale.id) {
      request = this.http.get(`/api/report/pos/${this.pointOfSale.id}/overview`,
        {params: params});
    } else if (this.distributor.id) {
      request = this.http.get(`/api/report/distributor/${this.distributor.id}/overview`,
        {params: params});
    } else {
      request = this.http.get(`/api/report/overview`,
        {params: params});
    }

    request.subscribe((reportResponse: any) => {
      this.overviewStatistics = reportResponse;
      setTimeout(() => {
        this.loading = false;
      });
    });
  }

  isSuperAdmin() {
    return this.authService.hasPermission(PermissionName.ROLE_SUPER_ADMIN);
  }

  downloadGlobalOverview() {
    const reader = new FileReader();
    const actionResult = this.http
      .post(`/api/report/overview/download`, {type: 'GLOBAL'}, {responseType: 'blob'})
      .subscribe((blob: Blob) => {
        reader.addEventListener('load', () => {
          const month = (new Date()).toLocaleDateString('de-DE',
            {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            });
          const downloadLinkElement = <HTMLLinkElement>document.getElementById('download-link');
          const url = window.URL.createObjectURL(blob);
          downloadLinkElement.href = url;
          downloadLinkElement.setAttribute('download', `Übersicht-${month}.xlsx`);
          downloadLinkElement.click();
          window.URL.revokeObjectURL(url);
        }, true);
        if (blob) {
          reader.readAsDataURL(blob);
        }
      });
  }

  downloadMonthlyOverview() {
    const month = (new Date()).toLocaleDateString('de-DE', {day: 'numeric', month: 'numeric', year: 'numeric'});
    this.downloadCustomOverview({type: 'MONTHLY', month: this.date.getUTCMonth(), year: this.date.getUTCFullYear()}, `Übersicht-monatliche-${month}.xlsx`);
  }

  downloadYearlyOverview() {
    const month = (new Date()).toLocaleDateString('de-DE', {day: 'numeric', month: 'numeric', year: 'numeric'});
    this.downloadCustomOverview({type: 'YEARLY', year: this.date.getUTCFullYear()}, `Übersicht-jährliche-${month}.xlsx`);
  }

  downloadCustomOverview(payload, targetFilename) {
    if (this.pointOfSale.id) {
      payload.pointOfSaleId = this.pointOfSale.id;
    } else if (this.distributor.id) {
      payload.distributorId = this.distributor.id;
    }
    const reader = new FileReader();
    const actionResult = this.http
      .post(`/api/report/overview/download`, payload, {responseType: 'blob'})
      .subscribe((blob: Blob) => {
        reader.addEventListener('load', () => {
          const downloadLinkElement = <HTMLLinkElement>document.getElementById('download-link');
          const url = window.URL.createObjectURL(blob);
          downloadLinkElement.href = url;
          downloadLinkElement.setAttribute('download', targetFilename);
          downloadLinkElement.click();
          window.URL.revokeObjectURL(url);
        }, true);
        if (blob) {
          reader.readAsDataURL(blob);
        }
      });
  }
}
