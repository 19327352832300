import {Observable} from 'rxjs/Rx';

export class FilterData {

  public type: string;
  public name: string;
  public key: string;
  public data: Observable<any>;
  public empty: any;
  public value: any = null;
  public model: any = null;
  public list: any = null;
  public source: any = null;
  public viewProperty: string;
  public descriptionProperty: string;
  public valueProperty: string;
  public searchProperties: string[];
  public role: string;

  constructor(filterData: Partial<FilterData>) {
    Object.assign(this, filterData);

    if (!this.searchProperties) {
      this.searchProperties = [filterData.viewProperty];
    }
  }

  public static asPartial = <T extends Partial<FilterData>>(t: T) => t;
}
