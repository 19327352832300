import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {PageNotFoundComponent} from './shared/component/not-found.component';

import {AppRoutingModule} from './app-routing.module';

import {BrowserModule, Title} from '@angular/platform-browser';
import {AuthService} from './shared/auth/auth.service';
import {LoginComponent} from './login/login.component';
import {LoginRoutingModule} from './login/login-routing.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './shared/auth/auth.interceptor';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeModule} from './home/home.module';
import {UnavailableComponent} from './shared/component/unavailable.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {OverlayComponent} from './shared/component/overlay.component';
import {OverlayService} from './shared/overlay.service';
import {MessagePopupComponent} from './shared/component/message-popup/message-popup.component';
import {MessageService} from './shared/message.service';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {TooltipModule} from '@progress/kendo-angular-tooltip';
import {PreloaderService} from './shared/preloader.service';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';

import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/de/all';
import {CldrIntlService, IntlService} from '@progress/kendo-angular-intl';
import {LanguageService} from './shared/language.service';
import {MonthSelectorComponent} from './shared/component/month-selector/month-selector.component';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';

registerLocaleData(localeDe, localeEn);

export const httpInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
];

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ButtonsModule,
    DateInputsModule,
    DialogModule,
    TooltipModule,
    LoginRoutingModule,
    HomeModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    UnavailableComponent,
    OverlayComponent,
    MessagePopupComponent,
    MonthSelectorComponent
  ],
  providers: [
    AuthService,
    httpInterceptorProviders,
    OverlayService,
    MessageService,
    LanguageService,
    PreloaderService,
    CldrIntlService,
    Title,
    {
      provide: IntlService,
      useExisting: CldrIntlService
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-US'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
