import {VoucherBase} from './voucher-base';
import {Voucher} from './voucher';

export class VoucherDTO extends VoucherBase {
  public status: string;

  constructor(init?: Partial<Voucher>) {
    super(init);
  }

}

