import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './shared/component/not-found.component';
import {UnavailableComponent} from './shared/component/unavailable.component';


const appRoutes: Routes = [
  {path: 'unavailable', component: UnavailableComponent},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
