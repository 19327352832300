import {Component, OnInit} from '@angular/core';
import {EntityService} from '../../module/user/entity.service';

@Component({
  selector: 'entity-statistic',
  templateUrl: './entity-statistic.component.html',
  styleUrls: ['./entity-statistic.component.scss']
})
export class EntityStatisticComponent implements OnInit {

  statistic: any[] = [];

  constructor(private entityService: EntityService) {
  }

  public onToggle(statistic): void {
    const show = statistic.show;
    this.closePopups();
    statistic.show = !show;
  }

  closePopup(statistic) {
    statistic.show = false;
  }

  closePopups() {
    this.statistic.forEach(s => this.closePopup(s));
  }

  ngOnInit() {
    if (this.entityService.statistic() !== null) {
      this.entityService.statistic().subscribe((statistic) => this.statistic = statistic);
    }
  }

}
