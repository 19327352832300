import {Observable} from 'rxjs/Observable';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {State} from '@progress/kendo-data-query';
import {DataStateChangeEvent, GridDataResult} from '@progress/kendo-angular-grid';
import {EntityService} from '../module/user/entity.service';
import {FormAction} from '../../shared/form/form-action';
import {FormActionName} from '../../shared/form/form-action-name';
import {MessageService} from '../../shared/message.service';

@Component({
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss']
})
export class EntityListComponent implements OnInit, OnDestroy {

  hideAddForPos = true;
  actionsForPos = true;
  public translatePrefix = '';
  public editDataItem: any;
  public editPopupActive = false;
  properties: any;
  public view: Observable<GridDataResult>;
  public state: State = {
    skip: 0,
    take: 10
  };

  public dataItem: any;

  min = Math.min;

  private commandsColumnWidth: number;
  private filterSubscription: any;
  private editSubscription: any;
  private createSubscription: any;

  constructor(public entityService: EntityService,
              @Inject('title') public title: string,
              private messageService: MessageService) {
    this.view = entityService;
    const layout = entityService.layout();
    this.properties = layout.form.filter(field => field.columnConfig.show);
    this.translatePrefix = layout.translatePrefix;
    this.commandsColumnWidth = layout.commandsColumnWidth ? layout.commandsColumnWidth : 120;
  }

  ngOnInit() {
    this.filterSubscription = this.entityService.entityFilter.subscribe(() => {
      this.state.skip = 0;
      this.entityService.read(this.state);
      console.log('filterSubscription');
    });

    this.editSubscription = this.entityService.entityEdit.subscribe((event) => {
      this.editHandler(event);
    });

    this.createSubscription = this.entityService.entityCreate.subscribe((event) => {
      this.editHandler(event);
    });

    if (this.entityService.apiEndPoint === 'users' || this.entityService.apiEndPoint === 'pos') {
      this.hideAddForPos = !(this.entityService.authService.authority.roleInPointOfSale.length > 0);
    }

  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
    this.editSubscription.unsubscribe();
    this.createSubscription.unsubscribe();
  }

  onHeaderClick(e) {
    e.target.parentNode.click();
  }

  getActions(model) {
    return this.entityService.listActions(model);
  }

  doAction(action, item) {
    const actionResult = this.entityService.doAction(action, item);
    if (actionResult) {
      actionResult.subscribe(() => {
        this.entityService.read(this.state);
        this.messageService.success();
      });
    }
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.entityService.read(state);
  }

  addHandler() {
    this.entityService.doAction(new FormAction({name: FormActionName.CREATE}), this.entityService.newInstance());
  }

  canAdd() {
    return this.entityService.canAdd();
  }

  getAddButtonTitle() {
    return this.entityService.getAddButtonTitle();
  }

  editHandler(event) {
    this.editDataItem = event;
    this.editPopupActive = true;
  }

  cancelHandler() {
    this.editDataItem = undefined;
    this.editPopupActive = false;
  }

  actionHandler(event: any) {
    const action = this.entityService.doAction(event.action, event.entity);
    if (action) {
      action.subscribe(() => {
        this.cancelHandler();
        this.entityService.read(this.state);
        this.messageService.success();
      });
    } else {
      this.cancelHandler();
    }
  }

  removeHandler({dataItem}) {
    const user = this.entityService.convert(dataItem);
    this.entityService.delete(user).subscribe(() => this.entityService.read(this.state));
  }
}
