import {Location} from '../../../../shared/model/location';
import {HasId} from '../../../../shared/model/interface/has-id';
import {Cloneable} from '../../../../shared/model/interface/cloneable';

export class DistributorDTO extends Cloneable<DistributorDTO> implements HasId {
  public id: string;
  public name: string;
  public shortName: string;
  public email: string;
  public phoneNumber: string;
  public description: string;
  public logotypeFilePath: string;
  public status: string;
  public locations: Location[];
  public posType: any;
  public contactPersonName: string;
  public contactPersonSurname: string;
  public contactPersonPhone: string;
  public contactPersonEmail: string;
  public contactPersonDescription: string;

  constructor(init?: Partial<DistributorDTO>) {
    super(init);
  }

  public getId() {
    return this.id;
  }
}
