import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {OverviewRoutingModule} from './overview-routing.module';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {TranslateModule} from '@ngx-translate/core';
import {OverviewComponent} from './overview.component';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {CldrIntlService, IntlModule, IntlService} from '@progress/kendo-angular-intl';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonsModule,
    OverviewRoutingModule,
    TranslateModule,
    DropDownsModule,
    DateInputsModule,
    InputsModule,
    IntlModule
  ],
  declarations: [
    OverviewComponent
  ],
  providers: [
    {provide: IntlService, useExisting: CldrIntlService}
  ]
})
export class OverviewModule {
}
