import {EntityStatus} from '../../../../shared/model/interface/entity-status';
import {StatusView} from '../../../../shared/model/interface/status-view';
import {UserStatusName} from './user-status-name';

export class UserStatus extends EntityStatus {

  public id: string;
  public name: UserStatusName;
  public view: StatusView;

  constructor(name: UserStatusName) {
    super();
    this.id = name;
    this.name = name;
    switch (name) {
      case UserStatusName.OPERATIVE:
        this.view = new StatusView('check', 'k-notification k-notification-success');
        break;
      case UserStatusName.INOPERATIVE:
        this.view = new StatusView('warning', 'k-notification k-notification-warning');
        break;
    }
  }

  getId() {
    return this.id;
  }

  getView() {
    return this.view;
  }
}
