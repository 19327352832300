import {Location} from '../../../../shared/model/location';
import {Cloneable} from '../../../../shared/model/interface/cloneable';

export class DistributorDeliveryDTO extends Cloneable<DistributorDeliveryDTO> {

  distributorId: string;
  distributor: any;
  zipCode: string;
  note: string;
  public locations: Location[];
  public location: Location;

  constructor(init?: Partial<DistributorDeliveryDTO>) {
    super(init);
    this.location = init.locations.length > 0 ? init.locations[0] : null;
    this.distributorId = init.distributor.id;
  }

}
