import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {AuthService} from './auth.service';
import {tap} from 'rxjs/internal/operators';
import {Router} from '@angular/router';
import {MessageService} from '../message.service';
import {ServerMessage} from '../model/message/server-message';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private router: Router, private messageService: MessageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = localStorage.getItem('X-A-TOKEN');

    let request = null;
    if (authToken) {
      request = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken)
      });
    } else {
      request = req;
    }

    // send cloned request with header to the next handler.
    return next.handle(request).pipe(tap(response => {
      if (response instanceof HttpResponse) {
        const token = response.headers.get('X-A-TOKEN');
        if (token) {
          localStorage.setItem('X-A-TOKEN', token);
        }
      }
    }, (errorResponse) => this.handleError(errorResponse)));
  }

  handleError(errorResponse: HttpErrorResponse) {
    switch (errorResponse.status) {
      case 401:
        this.router.navigate(['/login']);
        break;
      case 404:
      case 502:
      case 504:
        this.router.navigate(['/unavailable']);
        break;
      default:

        if (errorResponse.error !== null) {
          this.messageService.error(new ServerMessage(errorResponse.error));
        } else {
          this.messageService.error(new ServerMessage({
            message: errorResponse.statusText
          }));
        }

        console.error(errorResponse);
        break;
    }
  }
}
