import {Component} from '@angular/core';

@Component({
  template: `<b>empty component</b>`
})
export class EmptyComponent {

  constructor() {
  }

}
