import {Cloneable} from '../model/interface/cloneable';
import {FormActionName} from './form-action-name';

export class FormAction extends Cloneable<FormAction> {
  public name: FormActionName;
  public key: string;
  public object: any;

  constructor(init?: Partial<FormAction>) {
    super(init);
  }
}
