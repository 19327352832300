import {DataControl} from './data-control';
import {EditConfig} from './edit-config';

export class ListDataControl extends DataControl {

  public defaultValue: any;

  constructor(name,
              input,
              defaultValue,
              control,
              tableConfig,
              editConfig = {}) {
    super(name, input, control, tableConfig, editConfig);
    this.defaultValue = defaultValue;
    this.editConfig = new EditConfig(editConfig);
  }
}
