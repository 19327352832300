import {EventEmitter, Inject, Injectable, LOCALE_ID} from '@angular/core';
import {CldrIntlService, IntlService} from '@progress/kendo-angular-intl';
import {HttpClient} from '@angular/common/http';
import {shareReplay} from 'rxjs/internal/operators';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Injectable()
export class LanguageService {

  private DEFAULT_LOCALE = 'de';

  private APPLICATION_LANGUAGES = ['en', 'de'];

  public locale: string;

  public onLangChange: EventEmitter<LangChangeEvent>;

  constructor(private http: HttpClient,
              private translateService: TranslateService,
              @Inject(LOCALE_ID) public localeId: string,
              private intlService: IntlService) {
    this.onLangChange = this.translateService.onLangChange;
  }

  public init(preferences) {
    this.translateService.addLangs(this.APPLICATION_LANGUAGES);
    const locale = this.checkLanguage(this.get(preferences, 'ADMIN_PANEL_LANGUAGE'));
    this.translateService.use(locale);
    (<CldrIntlService>this.intlService).localeId = locale;
    this.locale = locale;
  }

  get(preferences, key) {
    const result = preferences.filter(preference => {
      return preference.key === key;
    });
    if (result.length > 0) {
      return result[0].value;
    }
    return undefined;
  }

  public translate(key) {
    return this.translateService.get(key);
  }

  fromPreference() {
    const request = this.http.get('api/preference')
      .pipe(shareReplay());
    request.subscribe((preferences) => {
      this.init(preferences);
    }, response => {
      this.startWithDefaults();
    });
    return request;
  }

  checkLanguage(language) {
    return language && this.APPLICATION_LANGUAGES.indexOf(language) > -1 ? language : this.DEFAULT_LOCALE;
  }

  startWithDefaults() {
    this.translateService.addLangs(this.APPLICATION_LANGUAGES);
    this.translateService.use(this.DEFAULT_LOCALE);
    this.locale = this.DEFAULT_LOCALE;
  }

  switchLanguage(locale) {
    this.translateService.use(locale);
    (<CldrIntlService>this.intlService).localeId = locale;
    this.locale = locale;
    return this.http.put('api/preference', {key: 'ADMIN_PANEL_LANGUAGE', value: locale});
  }

}
