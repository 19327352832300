import {Injectable} from '@angular/core';

@Injectable()
export class PreloaderService {

  public show() {
    document.getElementById('preloader').style.display = 'flex';
    document.body.classList.add('preloader-active');
  }

  public hide() {
    document.getElementById('preloader').style.display = 'none';
    document.body.classList.remove('preloader-active');
  }

  constructor() {
  }
}
