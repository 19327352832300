import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class OverlayService {
  // TODO
  public API = '/api';

  public overlayChanged: EventEmitter<boolean> = new EventEmitter();

  public show() {
    this.overlayChanged.emit(true);
  }

  public hide() {
    this.overlayChanged.emit(false);
  }

  constructor() {
  }
}
