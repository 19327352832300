import {TerminalBase} from './terminal-base';
import {TerminalStatus} from './terminal-status';
import {HasStatus} from '../../../../shared/model/interface/has-status';

export class Terminal extends TerminalBase implements HasStatus {
  public status: TerminalStatus;
  public location: Location;
  public health: any;

  constructor(init?: Partial<Terminal>) {
    super(init);
  }

  getStatus() {
    return this.status;
  }
}

