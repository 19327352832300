import {Component, Input} from '@angular/core';
import {DialogContentBase, DialogRef} from '@progress/kendo-angular-dialog';

@Component({
  selector: 'month-selector',
  styleUrls: ['./month-selector.component.scss'],
  templateUrl: './month-selector.component.html'
})
export class MonthSelectorComponent extends DialogContentBase {

  loading = false;

  value: Date = new Date();

  max = new Date();
  min = new Date(2019, 1, 1);

  constructor(dialog: DialogRef) {
    super(dialog);
  }

  public onCancelAction(): void {
    this.dialog.close({text: 'Cancel'});
  }

  public onConfirmAction(): void {
    this.dialog.close({text: 'Submit', primary: true, value: this.value});
  }
}
