export enum FormActionName {
  POS_INVOICE = 'pos-invoice',
  STATUS_CHANGE = 'status-change',
  ACTIVATE = 'activate-entity',
  RESTRICT = 'restrict-entity',
  DEACTIVATE = 'deactivate-entity',
  EMAIL_TIME_EDIT = 'email-time-edit',
  RETURNED_CUP_RESET = 'reset-terminal',
  CREATE = 'create',
  SAVE_ROLES = 'saveRoles',
  SAVE = 'save',
  POSES = 'poses',
  CANCEL = 'cancel',
  EDIT = 'edit',
  ADMINS = 'admins',
  BILLING = 'billing',
  SAVE_BILLING = 'save-billing',
  DELIVERY = 'delivery',
  SAVE_DELIVERY = 'save-delivery',
  DOWNLOAD_QR = 'download-qr',
  CHANGE_PASSWORD = 'change_password',

  ORDER_CLOSE = 'orderClose',
  ORDER_CANCEL = 'orderCancel'
}
