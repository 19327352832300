import {Component, OnInit} from '@angular/core';
import {DashboardLocation} from '../shared/model/dashboard-location';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../shared/config.service';
import {Filter} from './model/filter';
import {Card} from './model/card';
import {AuthService} from '../shared/auth/auth.service';
import {PermissionName} from '../shared/model/auth/role-name';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  locations = {};
  markers = [];
  statistic: any = {};
  cards = [
    new Card(
      'distributor',
      'entity.distributor.title',
      'dashboard.card.distributor.registered',
      'groups',
      '/partners',
      PermissionName.VIEW_DISTRIBUTOR
    ),
    new Card(
      'pos',
      'entity.pos.title',
      'dashboard.card.pos.registered',
      'store',
      '/points',
      null
    ),
    new Card(
      'order',
      'entity.order.title',
      'dashboard.card.order.registered',
      'directions_car',
      '/orders',
      PermissionName.VIEW_ORDER
    ),
    new Card(
      'terminal',
      'entity.terminal.title',
      'dashboard.card.terminal.registered',
      'kitchen',
      '/terminals',
      PermissionName.VIEW_TERMINAL
    )
  ];

  filters: Filter[] = [
    new Filter('distributor', 'entity.distributor.title', true),
    new Filter('pos', 'entity.pos.title', true),
    new Filter('terminal', 'entity.terminal.title', true)
  ];

  constructor(private http: HttpClient,
              private config: ConfigService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.locations = [];

    this.cards.forEach(card => {
      card.hasAccess = !card.permissionName || this.authService.hasPermission(card.permissionName);
      if (card.hasAccess) {
        this.http.get<any>(this.config.API + '/' + card.name).subscribe((entities) => {
          this.updateLocations(entities, card.name);
        });
      }
    });

    this.cards = this.cards.filter(card => card.hasAccess);
    this.filters = this.filters.filter(filter => !!this.cards.find(card => card.name === filter.name));
  }

  updateLocations(entities, entityType) {
    this.statistic[entityType] = entities.totalElements;
    this.locations[entityType] = [];
    if (entities.content && entities.content.length > 0) {
      entities.content.forEach((entity) => {
        if (entity.locations && entity.locations.length > 0) {
          entity.locations.forEach((entityLocation) => {
            const location = new DashboardLocation(entityLocation);
            location.type = entityType;
            location.object = entity;
            this.locations[entityType].push(location);
          });
        }
      });
    }

    this.markers = this.markers.concat(this.locations[entityType]);
  }

  doFilter(filter) {
    filter.active = !filter.active;
    this.markers = [];
    const active = this.filters.filter(f => f.active);
    active.forEach(f => {
      const locations = this.locations[f.name];
      this.markers = this.markers.concat(locations);
    });
  }
}
