import {Inject, Injectable, Injector} from '@angular/core';
import {User} from './model/user';
import {InstancePageableService} from '../../../shared/instance.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EntityChangeEvent} from '../../../shared/model/entity-change-event';
import {DataControl} from '../../../shared/form/data-control';
import {InputType} from '../../../shared/input-type';
import {UserStatusName} from './model/user-status-name';
import {UserStatus} from './model/user-status';
import {Observable} from 'rxjs/Rx';
import {UserDTO} from './model/user-dto';
import {ListDataControl} from '../../../shared/form/list-data-control';
import {TranslateService} from '@ngx-translate/core';
import {shareReplay} from 'rxjs/internal/operators';
import {FormActionName} from '../../../shared/form/form-action-name';
import {PermissionName} from '../../../shared/model/auth/role-name';
import {MessageService} from '../../../shared/message.service';
import {ServerMessage} from '../../../shared/model/message/server-message';
import {FormAction} from '../../../shared/form/form-action';
import {DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {SetPasswordComponent} from './set-password/set-password.component';
import {DistributorStatusName} from '../distributor/model/distributor-status-name';
import {StatusEditComponent} from '../pos/status-edit/status-edit.component';
import {FilterData} from '../../../shared/form/filter-data';

@Injectable()
export class EntityService extends InstancePageableService<User> {

  EMPTY_USER = {id: null, name: 'filter.user.empty'};

  USER_FILTER = {
    type: 'list',
    name: 'user',
    key: 'userId',
    empty: this.EMPTY_USER,
    viewProperty: 'name',
    valueProperty: 'id',
    data: null,
    model: this.EMPTY_USER,
    searchProperties: ['name', 'surname','username','email','phoneNumber']
  };

  public allUsers: Observable<User>;
  private userRoles = [];
  private filterList: any;

  public items = {
    'status': new Observable<any>(),
    'userRoles': new Observable<any>()
  };

  public STATUS = [
    new UserStatus(UserStatusName.OPERATIVE),
    new UserStatus(UserStatusName.INOPERATIVE)
  ];
  DEFAULT_ROLE: any;
  USER_NAME_VALIDATOR = Validators.compose([Validators.required, Validators.minLength(4)]);
  LOGIN_VALIDATOR = Validators.compose(
    [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(128)
    ]);
  USER_EMAIL_VALIDATOR = Validators.compose([Validators.required, Validators.email]);

  constructor(private dialogService: DialogService,
              injector: Injector,
              @Inject('APIEndpoint') public apiEndPoint: string,
              private translate: TranslateService,
              private messageService: MessageService) {

    super(injector);
    this.items.status = Observable.of(this.STATUS);

    this.allUsers =
      this.http.get<User>(this.apiPrefix + 'users', {params: {'size': '1000', 'showInactive': 'true'}});
    this.USER_FILTER.data = this.allUsers;

    this.filterList = [];
    this.filterList.push(new FilterData(FilterData.asPartial(this.USER_FILTER)));
    this.filterList.push(new FilterData(FilterData.asPartial({
        type: 'boolean',
        value: false,
        key: 'showInactive',
        valueProperty: 'value',
        role: PermissionName.ROLE_SUPER_ADMIN
      }))
    );

  }

  initRoleList() {
    this.DEFAULT_ROLE = {id: null, name: 'role.entity.role', type: null};

    this.translate.get(this.DEFAULT_ROLE.name).subscribe((name) => {
      this.DEFAULT_ROLE.name = name;
    });

    this.userRoles = [];
    this.items.userRoles = Observable.of(this.userRoles);
    if (this.authService.hasPermission(PermissionName.CREATE_BUYER)) {
      this.userRoles.push({id: -1, name: this.translate.instant('role.name.BUYER'), type: 'buyer'});
    }
    this.http.get<any>(this.apiPrefix + 'distributor').subscribe((distributors) => {
      distributors.content.forEach(distributor => {
        this.userRoles.push({
          id: distributor.id,
          name: distributor.name + ' / ' + this.translate.instant('role.distributor.ADMIN'),
          type: 'distributor',
          distributorId: distributor.id
        });
      });
    });

    this.http.get<any>(this.apiPrefix + 'pos').subscribe((poses) => {
      poses.content.forEach(pos => {
        this.userRoles.push({
          id: pos.id,
          name: pos.name + ' / ' + this.translate.instant('role.pos.ADMIN'),
          type: 'pos',
          distributorId: pos.distributor.id
        });
      });
    });
  }

  api(): string {
    return this.apiEndPoint;
  }

  getAddButtonTitle() {
    return 'entity.user.create.title';
  }

  convert(response: any): User {
    response.status = this.STATUS.find((userStatus) => {
      return userStatus.id === response.status;
    });
    return new User(response);
  }

  request(user): any {
    console.log(user);
    const request = new UserDTO(user);
    request.status = user.status ? user.status.id : null;
    if (!request.gender) {
      request.gender = 'EMPTY';
    }
    return request;
  }

  filters() {
    this.mergeFilters(this.filterList, this.query);
    return this.filterList;
  }

  private canCreateBuyers(): boolean {
    return this.authService.hasPermission(PermissionName.CREATE_BUYER);
  }

  create(user: User) {
    const requestEntity = this.request(user);
    const userNotSelected = user.userRoles === null || user.userRoles.type === 'buyer';

    if (this.canCreateBuyers() && userNotSelected) {
      return this.http.post(this.apiPrefix + 'users/register', requestEntity).pipe(shareReplay());
    } else if (!this.canCreateBuyers() && userNotSelected) {
      this.messageService.error(new ServerMessage({message: 'error.role.required'}));
      return null;
    } else if (user.userRoles.type === 'distributor' || user.userRoles.type === 'pos') {
      const userCreateUrl = this.apiPrefix + 'distributor/' + user.userRoles.distributorId + '/user';
      const userCreationRequest = this.http.post<User>(userCreateUrl, requestEntity)
        .pipe(shareReplay());
      userCreationRequest.subscribe((createdUser) => {
        const userRole = [{distributorRole: {id: 0}, pointOfSaleRole: {id: 0}, user: {id: createdUser.id}}];

        let roleRequest;
        if (user.userRoles.type === 'pos') {
          roleRequest = this.http.put(this.apiPrefix + 'pos/' + user.userRoles.id + '/user', userRole);
        } else {
          roleRequest = this.http.put(this.apiPrefix + 'distributor/' + user.userRoles.id + '/user', userRole);
        }
        roleRequest.subscribe();
      });
      return userCreationRequest;
    }
  }

  newInstance(): User {
    return new User({status: new UserStatus(UserStatusName.OPERATIVE)});
  }

  onChange(event: EntityChangeEvent, form: FormGroup) {
  }

  getImages(propertyName: string): any {
    return null;
  }

  getItems(propertyName: string): any {
    if (this.items.hasOwnProperty(propertyName)) {
      return this.items[propertyName];
    }

    return null;
  }

  listActions(model) {
    const actions = super.listActions(model);
    actions.push(new FormAction({name: FormActionName.CHANGE_PASSWORD, key: 'entity.user.password.set'}));

    if (model && this.authService.authority.details.id !== model.id) {
      actions.push(new FormAction({name: FormActionName.STATUS_CHANGE, key: 'entity.action.status'}));
    }

    return actions;
  }

  doAction(action: FormAction, object): Observable<Object> {
    switch (action.name) {
      case FormActionName.CHANGE_PASSWORD:
        const dialog: DialogRef = this.dialogService.open({
          content: SetPasswordComponent,
          width: 350
        });
        const userInfo = dialog.content.instance;
        userInfo.userId = object.id;
        return null;
      case FormActionName.STATUS_CHANGE:
        return this.onStatusChange(action, object);
    }

    return super.doAction(action, object);
  }

  onStatusChange(action, object) {
    return new Observable(observer => {
      const statusEditDialog: DialogRef = this.dialogService.open({
        title: this.translate.instant('entity.action.status'),
        content: StatusEditComponent,
        width: 250
      });

      const inputs = statusEditDialog.content.instance;
      inputs.available = [
        DistributorStatusName.OPERATIVE,
        DistributorStatusName.INOPERATIVE
      ];
      inputs.status = object.status;

      statusEditDialog.result.subscribe((result: any) => {
        if (result.text === 'Submit') {
          this.http.put(`${this.apiPrefix + 'users'}/${object.id}/${this.STATUS_ACTION_MAP[inputs.status.id]}`, null)
            .subscribe(() => observer.next(), () => observer.next());
        } else {
          observer.error();
        }
      });
    });
  }

  layout(entity = null, action = null) {
    const layout = {
      translatePrefix: 'entity.user.',
      commandsColumnWidth: 80,
      form: null
    };


    layout.form = [
      new DataControl(
        'id',
        InputType.VIEW,
        new FormControl(),
        {show: false}
      ),
      new DataControl(
        'name',
        InputType.TEXT,
        new FormControl('', this.USER_NAME_VALIDATOR),
        {type: 'text', minWidth: '1150px'},
        {required: true}
      ),
      new DataControl(
        'surname',
        InputType.TEXT,
        new FormControl('', this.USER_NAME_VALIDATOR),
        {type: 'text', minWidth: '1150px'},
        {required: true}
      ),
      new DataControl(
        'roles',
        InputType.ROLES,
        new FormControl(),
        {type: 'roles', minWidth: '680px'}
      ),
      new DataControl(
        'username',
        InputType.TEXT,
        new FormControl('', this.LOGIN_VALIDATOR),
        {},
        {required: true}
      ),
      new DataControl(
        'phoneNumber',
        InputType.TEXT,
        new FormControl('', this.USER_NAME_VALIDATOR),
        {minWidth: '1400px'},
        {required: true}
      ),
      new DataControl(
        'email',
        InputType.TEXT,
        new FormControl('', this.USER_EMAIL_VALIDATOR),
        {minWidth: '1400px'},
        {required: true}
      ),
      new DataControl(
        'status',
        InputType.LIST,
        new FormControl(),
        {type: 'status'},
        {readOnly: false, visible: false, translate: true, translatePrefix: 'entity.status.'})
    ];

    if (action && (action.name === FormActionName.CREATE)) {
      this.initRoleList();

      layout.form.unshift(new ListDataControl(
        'userRoles',
        InputType.LIST,
        this.DEFAULT_ROLE,
        new FormControl(),
        {show: false},
        {required: true}
      ));

      layout.form.push(new DataControl(
        'password',
        InputType.PASSWORD,
        new FormControl('',
          Validators.compose([
            Validators.required,
            Validators.minLength(6)
          ])),
        {show: false},
        {required: true}
      ));
    }

    return layout;

  }
}
