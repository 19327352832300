import {Component, Input} from '@angular/core';
import {TerminalHealth} from '../../module/terminal/model/terminal-health';

@Component({
  selector: 'terminal-status',
  templateUrl: './terminal-status.component.html',
  styleUrls: ['./terminal-status.component.scss']
})
export class TerminalStatusComponent {

  @Input()
  health: TerminalHealth;
  @Input()
  ip: string;

}
