import {Location} from './location';

export class DashboardLocation extends Location {

  public type: string;
  public object: any;

  constructor(init?: Partial<DashboardLocation>) {
    super(init);
  }
}
