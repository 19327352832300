import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {DashboardComponent} from './dashboard.component';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {DashboardLocationComponent} from './location/dashboard-location.component';
import {ConfigService} from '../shared/config.service';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonsModule,
    DashboardRoutingModule,
    TranslateModule
  ],
  declarations: [
    DashboardComponent,
    DashboardLocationComponent
  ],
  providers: [ConfigService]
})
export class DashboardModule {
}
