import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from './shared/auth/auth.service';
import {PreloaderService} from './shared/preloader.service';
import {LanguageService} from './shared/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  constructor(private authService: AuthService,
              private preloaderService: PreloaderService,
              private language: LanguageService) {
  }

  ngOnInit() {
    this.authService.getCurrentUser()
      .subscribe(() => {
        this.language.fromPreference()
          .subscribe(() => this.hidePreloader(), () => this.hidePreloader());
      }, () => {
        this.language.startWithDefaults();
        this.hidePreloader();
      });
  }

  private hidePreloader() {
    setTimeout(() => {
      this.preloaderService.hide();
    }, 250);
  }
}
