import {TerminalBase} from './terminal-base';
import {Terminal} from './terminal';

export class TerminalDTO extends TerminalBase {
  public status: string | number;
  public locations: Location[];

  constructor(init?: Partial<Terminal>) {
    super(init);
  }
}

