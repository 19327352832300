import {Component, forwardRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {OverlayService} from '../../../shared/overlay.service';

@Component({
  selector: 'custom-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomDateComponent),
      multi: true,
    }]
})
export class CustomDateComponent implements ControlValueAccessor, Validator, OnInit {
  private parseError: boolean;
  public value: Date;

  @Input()
  public name: string;

  @Input()
  public readonly: boolean;

  @Input()
  public translatePrefix = '';

  constructor(private overlayService: OverlayService) {
  }

  ngOnInit() {
  }

  // this is the initial value set to the component
  public writeValue(obj: any) {
    this.value = obj;
  }

  // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  // validates the form, returns null when valid else the validation object
  // in this case we're checking if the json parsing has passed or failed from the onChange method
  public validate(c: FormControl) {
    return null;
  }

  // the method set in registerOnChange to emit changes back to the form
  private propagateChange(data) {
    return data;
  }

  public onChange(date: any): void {
    this.propagateChange(date);
  }

  public onOpen(): void {
    this.overlayService.show();
  }

  public onClose(): void {
    this.overlayService.hide();
  }
}
