import {Component, Input} from '@angular/core';

@Component({
  selector: 'pos-qr',
  styleUrls: ['./pos-qr.component.scss'],
  template: `
    <div class="pos-qr-block">
      <div class="pos-qr" [hidden]="!imagePrivate">
        <img src="{{imagePrivate}}"
             alt="QR error"
             name="{{posName}}">
             
        <a class="btn btn-sm btn-outline-secondary"
           href="{{imagePrivate}}"
           download="{{posName + '-get-qr.png'}}"
           translate="entity.pos.private.qr"></a>
      </div>

      <div class="pos-qr">
        <img src="{{imagePublic}}"
             alt="QR error"
             name="{{posName}}">

        <a class="btn btn-sm btn-outline-secondary"
           href="{{imagePublic}}"
           download="{{posName + '-spend-qr.png'}}"
           translate="entity.pos.public.qr"></a>
      </div>

    </div>
  `
})
export class PosQrComponent {
  @Input() public imagePrivate: string;
  @Input() public imagePublic: string;
  @Input() public posName: string;
}
