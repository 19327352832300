import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {EntityService} from '../../module/user/entity.service';
import {FilterData} from '../../../shared/form/filter-data';
import {AuthService} from '../../../shared/auth/auth.service';

@Component({
  selector: 'entity-filter',
  templateUrl: './entity-filter.component.html',
  styleUrls: ['./entity-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EntityFilterComponent implements OnInit {

  filters: FilterData[];
  disableShowInactive;

  constructor(private entityService: EntityService,
              private authService: AuthService) {
  }

  static filterItems(filter, item, value) {
    for (let i = 0; i < filter.searchProperties.length; i++) {
      const searchProperty = filter.searchProperties[i];
      if (EntityFilterComponent.present(searchProperty, item, value)) {
        return true;
      }
    }
    return false;
  }

  static present(searchProperty, item, value) {
    return item.hasOwnProperty(searchProperty)
      && item[searchProperty]
      && String(item[searchProperty])
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1;
  }

  ngOnInit() {
    this.filters = this.entityService.filters();
    this.disableShowInactive = (this.entityService.authService.authority.roleInPointOfSale
      && this.entityService.authService.authority.roleInPointOfSale.length > 0) && this.entityService.apiEndPoint === 'pos';

    if (this.filters !== null) {
      this.loadData();
      this.updateQuery();
    }

    this.entityService.entitySave.subscribe(() => {
      this.loadData();
    });

    this.refreshGrid();
  }

  hasPermission(permissionName) {
    return !permissionName || this.authService.hasPermission(permissionName);
  }

  loadData() {
    if (this.filters !== null) {
      this.filters.forEach(filter => {
        if (filter.data) {
          filter.data.subscribe((data) => {
            filter.list = data.content;
            filter.source = data.content;
            if (filter.value) {
              filter.model = filter.source.find(item => item[filter.valueProperty] === filter.value);
            }
          });
        }
      });
    }
  }

  handleFilter(filter, value) {
    filter.list = filter.source.filter((item) => {
      return EntityFilterComponent.filterItems(filter, item, value);
    });
  }

  valueChange(filter, value) {
    filter.value = value[filter.valueProperty];
    this.updateQuery();
    this.refreshGrid();
  }

  updateQuery() {
    const query = {};
    this.filters.forEach(f => {
      // filter.value = value[filter.valueProperty];
      if (f.value) {
        query[f.key] = f.value;
      }
    });
    this.entityService.setQuery(query);
  }

  refreshGrid() {
    this.entityService.entityFilter.emit();
    console.log('refresh filter');
    // this.entityService.read({skip: 0, take: 10});
  }
}
