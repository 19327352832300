import {Location} from '../../../../shared/model/location';
import {HasId} from '../../../../shared/model/interface/has-id';
import {Cloneable} from '../../../../shared/model/interface/cloneable';
import {DistributorStatus} from './distributor-status';
import {HasStatus} from '../../../../shared/model/interface/has-status';

export class Distributor extends Cloneable<Distributor> implements HasId, HasStatus {
  public id: string;
  public name: string;
  public shortName: string;
  public email: string;
  public phoneNumber: string;
  public description: string;
  public logotypeFilePath: string;
  public status: DistributorStatus;
  public location: Location;

  public contactPersonName: string;
  public contactPersonSurname: string;
  public contactPersonPhone: string;
  public contactPersonEmail: string;
  public contactPersonDescription: string;

  constructor(init?: Partial<Distributor>) {
    super(init);
  }

  public getId() {
    return this.id;
  }

  getStatus() {
    return this.status;
  }
}

