import {Component, forwardRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {EntityService} from '../../module/user/entity.service';

@Component({
  selector: 'custom-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomListComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomListComponent),
      multi: true,
    }]
})
export class CustomListComponent implements ControlValueAccessor, Validator, OnInit {

  public  itemList = [];
  public item = null;
  public isError = false;

  @Input()
  public name: string;

  @Input()
  public readonly: boolean;

  @Input()
  public defaultValue: string;

  @Input()
  public translatePrefix = '';

  @Input()
  public required: boolean;

  @Input()
  public translateConfig = {translate: false, translatePrefix: ''};

  constructor(private entityService: EntityService) {
  }

  ngOnInit() {
    const items = this.entityService.getItems(this.name);
    if (items !== null) {
      items.subscribe((response) => {
        if (response.hasOwnProperty('content')) {
          this.itemList = response['content'];
        } else {
          this.itemList = response;
        }
      });
    }
  }

  // this is the initial value set to the component
  public writeValue(obj: any) {
    this.item = obj;
  }

  // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  // validates the form, returns null when valid else the validation object
  // in this case we're checking if the json parsing has passed or failed from the onChange method
  public validate(c: FormControl) {

    // TODO: directive attribute required
    if (this.required && (c.value === null || c.value.id === null)) {
      if (c.dirty) {
        this.isError = true;
      }

      return {required: true};
    }

    this.isError = false;
    return null;
  }

  // the method set in registerOnChange to emit changes back to the form
  private propagateChange(data) {
    return data;
  }

  public selectionChange(item: any): void {
    this.propagateChange(item);
  }
}
