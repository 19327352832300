import {Inject, Injectable, Injector} from '@angular/core';
import {InstancePageableService} from '../../../shared/instance.service';
import {FormControl} from '@angular/forms';
import {InputType} from '../../../shared/input-type';
import {DataControl} from '../../../shared/form/data-control';
import {Voucher} from './model/voucher';
import {VoucherDTO} from './model/voucher-dto';
import {VoucherStatus} from './model/voucher-status';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/internal/operators';
import {VoucherStatisticModel} from './model/voucher-statistic-model';


@Injectable()
export class VoucherService extends InstancePageableService<Voucher> {

  VOUCHER_ICONS = {
    'PAPER': 'receipt',
    'SCREEN': 'phone_android',
    'HANDED': 'free_breakfast'
  };

  public items = {'status': new Observable<any>()};

  constructor(injector: Injector, @Inject('APIEndpoint') protected apiEndPoint: string) {
    super(injector);
  }

  api(): string {
    return this.apiEndPoint;
  }

  convert(response: any): Voucher {
    response.status = new VoucherStatus(response.status);
    return new Voucher(response);
  }

  request(item: Partial<Voucher>): any {
    const request = new VoucherDTO(item);
    request.status = item.status.id;
    return request;
  }

  newInstance(data): Voucher {
    return null;
  }

  listActions(model) {
    return [];
  }

  canAdd() {
    return false;
  }

  static getTotalVouchers(entityStatistic): number {
    return (entityStatistic && entityStatistic.totalAmount) ? entityStatistic.totalAmount : 0;
  }

  static getTotalUsedVouchers(entityStatistic): number {
    return (entityStatistic && entityStatistic.utilizedAmount) ? entityStatistic.utilizedAmount : 0;
  }

  static getPercentUsed(entityStatistic) {
    const total = VoucherService.getTotalVouchers(entityStatistic);
    const totalUsed = VoucherService.getTotalUsedVouchers(entityStatistic);

    return (total === 0 || totalUsed === 0) ? 0 : Math.round(totalUsed / total * 100);
  }

  statistic() {
    return this.http.get('api/statistics/voucher')
      .pipe(map((entityStatistic: VoucherStatisticModel) => {
        const total = VoucherService.getTotalVouchers(entityStatistic.amount);
        const used = VoucherService.getTotalUsedVouchers(entityStatistic.amount);
        const percent = VoucherService.getPercentUsed(entityStatistic.amount);

        const statistic: any[] = [
          {
            name: 'vouchers.statistic.vouchers',
            parameters: {total: total, used: used, percent: percent},
            type: 'percent'
          }
        ];

        const issuedBlock = {
          name: 'vouchers.statistic.issued.summary',
          parameters: {value: 0},
          child: [],
          type: 'icon-list'
        };

        const issuedTotal = this.convertStatistics(
          entityStatistic.issued,
          'vouchers.statistic.issued.',
          'totalIssued');

        issuedBlock.parameters.value = issuedTotal.sum;

        issuedBlock.child.push(issuedTotal);

        issuedBlock.child.push(this.convertStatistics(
          entityStatistic.issued,
          'vouchers.statistic.issued.',
          'totalIssuedMonth'));

        issuedBlock.child.push(this.convertStatistics(
          entityStatistic.issued,
          'vouchers.statistic.issued.',
          'totalIssued365'));

        statistic.push(issuedBlock);

        const usedBlock = {
          name: 'vouchers.statistic.used.summary',
          parameters: {value: 0},
          child: [],
          type: 'icon-list'
        };

        const usedTotal = this.convertStatistics(
          entityStatistic.used,
          'vouchers.statistic.used.',
          'totalUsed');

        usedBlock.parameters.value = usedTotal.sum;

        usedBlock.child.push(usedTotal);

        usedBlock.child.push(this.convertStatistics(
          entityStatistic.used,
          'vouchers.statistic.used.',
          'totalUsedMonth'));

        usedBlock.child.push(this.convertStatistics(
          entityStatistic.used,
          'vouchers.statistic.used.',
          'totalUsed365'));

        statistic.push(usedBlock);

        return statistic;
      }));
  }

  private convertStatistics(statistic, name, propertyName) {
    const converted = {
      name: name + propertyName,
      parameters: [],
      sum: 0
    };

    statistic.forEach(issuedStatistic => {
      converted.parameters.push({
        icon: this.VOUCHER_ICONS[issuedStatistic.voucherType],
        title: 'voucher.type.' + issuedStatistic.voucherType,
        value: issuedStatistic[propertyName]
      });
      converted.sum += issuedStatistic[propertyName];
    });

    converted.parameters.sort((a, b) => a.title > b.title ? 1 : -1);

    return converted;
  }

  layout() {
    return {
      translatePrefix: 'entity.voucher.',
      form: [
        new DataControl(
          'voucherType',
          InputType.VIEW,
          new FormControl(),
          {show: true, type: 'voucherType', width: 50}
        ),
        new DataControl(
          'id',
          InputType.VIEW,
          new FormControl(),
          {show: true, type: 'text'}
        ),
        new DataControl(
          'issueDate',
          InputType.DATE,
          new FormControl(),
          {show: true, type: 'date'}
        ),
        new DataControl(
          'terminal',
          InputType.LIST,
          new FormControl(),
          {show: true, type: 'object', viewProperty: 'identifier'}
        ),
        new DataControl(
          'pointOfSale',
          InputType.LIST,
          new FormControl(),
          {show: true, type: 'object', viewProperty: 'name'}
        ),
        new DataControl(
          'useDate',
          InputType.DATE,
          new FormControl(),
          {show: true, type: 'date'}
        ),
        new DataControl(
          'status',
          InputType.LIST,
          new FormControl(),
          {show: true, type: 'status'},
          {required: true, readOnly: false, visible: true, translate: true, translatePrefix: 'entity.voucher.status.'}
        ),
      ]
    };
  }
}



