import {HasId} from '../../../../shared/model/interface/has-id';
import {Cloneable} from '../../../../shared/model/interface/cloneable';
import {UserStatus} from './user-status';
import {HasStatus} from '../../../../shared/model/interface/has-status';

export class User extends Cloneable<User> implements HasId, HasStatus {
  public id?: number;
  public username?: string;
  public name?: string;
  public surname?: string;
  public email?: string;
  public roles?: string[];
  public userRoles?: any;
  public status?: UserStatus;
  public password?: string;

  constructor(init?: Partial<User>) {
    super(init);
  }

  public getId(): number {
    return this.id;
  }

  getStatus() {
    return this.status;
  }
}

