import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {shareReplay} from 'rxjs/internal/operators';
import {Router} from '@angular/router';
import {UserLogin} from '../model/user-login';
import {User} from '../../entity/module/user/model/user';
import {MessageService} from '../message.service';
import {PermissionName} from '../model/auth/role-name';

@Injectable()
export class AuthService {
  authority: any = {};
  isLoggedIn = null;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(private http: HttpClient, public router: Router, private messageService: MessageService) {
  }

  login(user: UserLogin): Observable<User> {
    const request: Observable<User> = this.http.post<User>('api/users/signIn', user)
      .pipe(shareReplay());
    request.subscribe(() => {
      this.getCurrentUser();
      this.isLoggedIn = true;
    }, response => this.handleError(response));
    return request;
  }

  init(response: any) {
    this.isLoggedIn = true;
    this.authority = response;
  }

  getCurrentUser(): Observable<User> {
    const request: Observable<User> = this.http.get<User>('api/users/me')
      .pipe(shareReplay());
    request.subscribe(response => this.init(response), response => this.handleError(response));
    return request;
  }

  canActivate(): Observable<boolean> | boolean {
    return localStorage.getItem('X-A-TOKEN') !== null;
  }

  hasAnyPermissions(permissionList: PermissionName[]) {
    return !!permissionList.find(permission => {
      return this.hasPermission(permission);
    });
  }

  hasPermission(permission) {
    let hasAccess = false;
    if (this.authority) {

      // cheking global roles
      if (this.authority.roles) {
        hasAccess = !!this.authority.roles.find((role) => {
          return role.name === permission || !!role.permissions.find((rolePermission) => {
            return rolePermission.name === permission;
          });
        });
      }

      // checking distributor
      if (this.authority.roleInDistributor && this.authority.roleInDistributor.length > 0) {
        const distributorAdmins = this.authority.roleInDistributor.filter(role => {
          return role.distributorRole !== null;
        });

        if (distributorAdmins.length !== 0) {
          switch (permission) {
            case PermissionName.VIEW_DISTRIBUTOR:
            case PermissionName.VIEW_POS:
            case PermissionName.VIEW_DASHBOARD:
            case PermissionName.VIEW_ORDER:
            case PermissionName.VIEW_USER:
            // tslint:disable-next-line:comment-format
            //todo: following for "Show inactive"
            case 'ROLE_SUPER_ADMIN':
              return true;
          }
        }
      }

      // checking POS
      if (this.authority.roleInPointOfSale && this.authority.roleInPointOfSale.length > 0) {
        const posAdmins = this.authority.roleInPointOfSale.filter(role => {
          return role.posRole !== null;
        });

        if (posAdmins.length !== 0) {
          switch (permission) {
            case PermissionName.VIEW_DASHBOARD:
            case PermissionName.VIEW_ORDER:
            case PermissionName.VIEW_POS:
            case PermissionName.VIEW_USER:
            // tslint:disable-next-line:comment-format
            //todo: following for "Show inactive"
            case 'ROLE_SUPER_ADMIN':
              return true;
          }
        }
      }
    }

    return hasAccess;
  }

  private handleError(response: HttpErrorResponse) {
    this.isLoggedIn = false;

    if (response.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', response.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,

      // this.messageService.error(response.error);
      console.error(
        `Backend returned code ${response.status}, ` +
        `body was: ${response.error}`);
    }

    // this.router.navigate(['/']);
    // return an observable with a user-facing error message
    // return throwError('Something bad happened; please try again later.');
  }

  logout(): void {
    this.isLoggedIn = false;
    this.authority = {};
    localStorage.removeItem('X-A-TOKEN');
    // TODO: move to component
    this.router.navigate(['login']);
  }
}
