import {Inject, Injectable, Injector} from '@angular/core';
import {InstancePageableService} from '../../../shared/instance.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InputType} from '../../../shared/input-type';
import {DataControl} from '../../../shared/form/data-control';
import {Terminal} from './model/terminal';
import {TerminalDTO} from './model/terminal-dto';
import {TerminalStatus} from './model/terminal-status';
import {Observable} from 'rxjs/Observable';
import {TerminalStatusName} from './model/termnal-status-name';
import {FormAction} from '../../../shared/form/form-action';
import {FormActionName} from '../../../shared/form/form-action-name';
import {MessageService} from '../../../shared/message.service';
import {EntityChangeEvent} from '../../../shared/model/entity-change-event';
import {FilterData} from '../../../shared/form/filter-data';
import {map} from 'rxjs/internal/operators';
import {PermissionName} from '../../../shared/model/auth/role-name';


@Injectable()
export class TerminalService extends InstancePageableService<Terminal> {

  filterList: any;

  TERMINAL_FILTER = {
    type: 'list',
    name: 'terminal',
    key: 'terminalId',
    empty: {id: null, identifier: 'filter.terminal.empty'},
    viewProperty: 'identifier',
    descriptionProperty: 'id',
    valueProperty: 'id',
    data: null,
    searchProperties: ['id', 'identifier', 'description']
  };

  IDENTIFIER_VALIDATOR = Validators.compose([Validators.required, Validators.minLength(4)]);

  public items = {'status': new Observable<any>()};

  public STATUS = [
    new TerminalStatus(TerminalStatusName.OPERATIVE),
    new TerminalStatus(TerminalStatusName.INOPERATIVE)
  ];

  constructor(injector: Injector,
              @Inject('APIEndpoint') protected apiEndPoint: string,
              protected messageService: MessageService) {
    super(injector);
    this.items.status = Observable.of(this.STATUS);
    this.TERMINAL_FILTER.data =
      this.http.get<Terminal>(this.apiPrefix + 'terminal', {params: {'size': '1000'}});
    this.filterList = [];

    this.filterList.push(new FilterData(FilterData.asPartial(this.TERMINAL_FILTER)));
    this.filterList.push(new FilterData(FilterData.asPartial({
      type: 'boolean',
      value: false,
      key: 'showInactive',
      valueProperty: 'value',
      role: PermissionName.ROLE_SUPER_ADMIN
    })));
  }

  api(): string {
    return this.apiEndPoint;
  }

  getAddButtonTitle() {
    return "entity.terminal.create.title";
  }

  convert(response: any): Terminal {
    const locations = response['locations'];
    response.location = locations.length > 0 ? locations[0] : null;
    delete response['locations'];
    response.status = this.STATUS.find((terminalStatus) => {
      return terminalStatus.id === response.status;
    });
    return new Terminal(response);
  }

  request(item: Partial<Terminal>): any {
    const request = new TerminalDTO(item);
    if (item.location !== null) {
      request.locations = [];
      request.locations.push(item.location);
    }
    request.status = item.status.id;

    return request;
  }

  newInstance(data): Terminal {
    return data ? new Terminal(data) : new Terminal({status: this.STATUS[0]});
  }

  onChange(event: EntityChangeEvent, form: FormGroup) {
    switch (event.type) {
      case 'location':
        const patch = {};
        const place = event.object;
        if (place.opening_hours && place.opening_hours.weekday_text) {
          patch['businessHours'] = place.opening_hours.weekday_text.join('\n').trim();
        }
        form.patchValue(patch);
        break;
    }
  }

  filters() {
    this.mergeFilters(this.filterList, this.query);
    return this.filterList;
  }

  statistic() {
    return this.http.get('api/statistics/terminal')
      .pipe(map((entityStatistic) => {
        return [
          {
            name: 'terminal.statistic.totalReturned',
            value: entityStatistic['totalReturned'],
            type: 'text'
          },
          {
            name: 'terminal.statistic.totalReturnedMonth',
            value: entityStatistic['totalReturnedMonth'],
            type: 'text'
          },
          {
            name: 'terminal.statistic.totalReturned365',
            value: entityStatistic['totalReturned365'],
            type: 'text'
          },
        ];
      }));
  }

  layout() {
    return {
      translatePrefix: 'entity.terminal.',
      commandsColumnWidth: 80,
      form: [
        new DataControl('health',
          InputType.HEALTH,
          new FormControl(),
          {type: 'health-status', width: 50, showHeader: false, sortable: false},
          {readOnly: false, visible: false, translate: false, translatePrefix: ''}
        ),
        new DataControl(
          'id',
          InputType.VIEW,
          new FormControl(),
          {width: 250}
        ),
        new DataControl(
          'location',
          InputType.LOCATION,
          new FormControl(),
          {show: false}
        ),
        new DataControl(
          'identifier',
          InputType.TEXT,
          new FormControl('', this.IDENTIFIER_VALIDATOR),
          {minWidth: '1150px'},
          {required: true}
        ),
        new DataControl(
          'returnedCupQuantity',
          InputType.VIEW,
          new FormControl(),
          {minWidth: '1200px', width: 150},
          {visible: false}
        ),
        new DataControl(
          'totalReturnedCupQuantity',
          InputType.VIEW,
          new FormControl(),
          {minWidth: '1200px', width: 140},
          {visible: false}
        ),
        new DataControl(
          'description',
          InputType.AREA,
          new FormControl(),
          {minWidth: '1400px'}
        ),
        new DataControl(
          'terminalPictureFilePath',
          InputType.IMAGE,
          new FormControl(),
          {show: false}
        ),
        new DataControl(
          'businessHours',
          InputType.AREA,
          new FormControl(),
          {show: false}
        ),
        new DataControl('health',
          InputType.HEALTH,
          new FormControl(),
          {type: 'health-errors', minWidth: '1050px', width: 250},
          {readOnly: false, visible: false, translate: false, translatePrefix: ''}
        ),
        new DataControl('status',
          InputType.LIST,
          new FormControl(),
          {type: 'status', minWidth: '920px', width: 120},
          {readOnly: false, visible: true, translate: true, translatePrefix: 'entity.status.'}
        ),
      ]
    };
  }

  getItems(propertyName: string): any {
    if (this.items.hasOwnProperty(propertyName)) {
      return this.items[propertyName];
    }

    return null;
  }

  listActions(model) {
    const actions = super.listActions(model);
    actions.push(new FormAction({name: FormActionName.RETURNED_CUP_RESET, key: 'entity.action.reset'}));
    return actions;
  }

  doAction(action: FormAction, object) {
    switch (action.name) {
      case FormActionName.RETURNED_CUP_RESET:
        this.http.put(this.apiPrefix + 'terminal/' + object.id + '/reset', null)
          .subscribe(() => {
            this.messageService.success();
            object.returnedCupQuantity = 0;
          });
        break;
    }

    return super.doAction(action, object);
  }

}



