import {Component, Input} from '@angular/core';
import {TerminalHealth} from '../../module/terminal/model/terminal-health';

@Component({
  selector: 'terminal-errors',
  templateUrl: './terminal-errors.component.html',
  styleUrls: ['./terminal-errors.component.scss']
})
export class TerminalErrorsComponent {

  @Input()
  health: TerminalHealth;

}
