import {Injectable} from '@angular/core';
import {DataControl} from '../../../../shared/form/data-control';
import {InputType} from '../../../../shared/input-type';
import {FormControl, Validators} from '@angular/forms';
import {RoleListDataControl} from '../../../../shared/form/role-list-data-control';
import {AuthService} from '../../../../shared/auth/auth.service';
import {PermissionName} from '../../../../shared/model/auth/role-name';

@Injectable()
export class DistributorFormsService {

  EMPTY_USER = {id: null, name: 'filter.user.empty'};

  DISTRIBUTOR_NAME_VALIDATOR = Validators.compose([
    Validators.required,
    Validators.minLength(4),
    Validators.maxLength(256)
  ]);

  DISTRIBUTOR_SHORT_NAME_VALIDATOR = Validators.compose([
    Validators.required,
    Validators.minLength(4),
    Validators.maxLength(32)
  ]);

  constructor(private authService: AuthService) {
  }

  isSuperAdmin() {
    return this.authService.hasPermission(PermissionName.ROLE_SUPER_ADMIN);
  }

  getEditForm() {
    const layout = [
      new DataControl(
        'id',
        InputType.VIEW,
        new FormControl(),
        {show: false}
      ),
      new DataControl(
        'location',
        InputType.LOCATION,
        new FormControl(),
        {show: false}
      ),
      new DataControl(
        'name',
        InputType.TEXT,
        new FormControl('', this.DISTRIBUTOR_NAME_VALIDATOR),
        {minWidth: '920px'},
        {required: true}
      ),
      new DataControl(
        'shortName',
        InputType.TEXT,
        new FormControl('', this.DISTRIBUTOR_SHORT_NAME_VALIDATOR),
        {},
        {required: true}
      ),
      new DataControl(
        'abbreviation',
        InputType.TEXT,
        new FormControl('', Validators.required),
        {show: false},
        {required: true}
      ),
      new DataControl(
        'email',
        InputType.TEXT,
        new FormControl('', Validators.email),
        {show: false}
      ),
      new DataControl(
        'phoneNumber',
        InputType.TEXT,
        new FormControl('', Validators.maxLength(32)),
        {minWidth: '600px'}
      ),
      new DataControl(
        'description',
        InputType.AREA,
        new FormControl(),
        {minWidth: '1200px'}
      ),
      new DataControl(
        'logotypeFilePath',
        InputType.IMAGE,
        new FormControl(),
        {show: false}
      ),
      new DataControl(
        'status',
        InputType.LIST,
        new FormControl(),
        {type: 'status'},
        {readOnly: false, visible: false, translate: true, translatePrefix: 'entity.status.'}
      ),
      new DataControl(
        'contactPersonName',
        InputType.TEXT,
        new FormControl('', Validators.maxLength(256)),
        {show: false}
      ),
      new DataControl(
        'contactPersonSurname',
        InputType.TEXT,
        new FormControl('', Validators.maxLength(256)),
        {show: false}
      ),
      new DataControl(
        'contactPersonPhone',
        InputType.TEXT,
        new FormControl('', Validators.maxLength(256)),
        {show: false}
      ),
      new DataControl(
        'contactPersonEmail',
        InputType.TEXT,
        new FormControl('', Validators.compose([
          Validators.email,
          Validators.maxLength(256)
        ])),
        {show: false}
      ),
      new DataControl(
        'contactPersonDescription',
        InputType.TEXT,
        new FormControl('', Validators.maxLength(256)),
        {show: false}
      )
    ];

    if (!this.isSuperAdmin()) {
      layout.forEach((dataControl: DataControl) => {
        dataControl.editConfig.readOnly = true;
      });
    }

    return layout;
  }


  getAdminForm() {
    return [
      new RoleListDataControl('userRole',
        InputType.ORGANIZATION_ROLE,
        1,
        this.EMPTY_USER,
        new FormControl(),
        {
          show: true,
          type: 'text'
        })
    ];
  }

  getBillingForm() {
    return [
      new DataControl(
        'distributorId',
        InputType.VIEW,
        new FormControl(),
        {show: false}
      ),
      new DataControl(
        'location',
        InputType.LOCATION,
        new FormControl('', Validators.required),
        {show: false},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: ''}
      ),
      new DataControl(
        'billingName',
        InputType.TEXT,
        new FormControl('', this.DISTRIBUTOR_NAME_VALIDATOR),
        {},
        {required: true}
      ),
      new DataControl(
        'vatNumber',
        InputType.NUMERIC,
        new FormControl('', Validators.compose(
          [
            Validators.required,
            Validators.min(0),
            Validators.max(100)
          ])),
        {},
        {required: true}
      ),
      new DataControl(
        'zipCode',
        InputType.TEXT,
        new FormControl(),
        {}
      ),
      new DataControl(
        'accountantEmail',
        InputType.TEXT,
        new FormControl('', Validators.compose([Validators.email, Validators.required])),
        {},
        {required: true}
      ),
      new DataControl(
        'note',
        InputType.AREA,
        new FormControl(),
        {}
      )
    ];
  }

  getDeliveryForm() {
    return [
      new DataControl(
        'distributorId',
        InputType.VIEW,
        new FormControl(),
        {show: false}
      ),
      new DataControl(
        'location',
        InputType.LOCATION,
        new FormControl('', Validators.required),
        {show: false},
        {required: true, readOnly: false, visible: true, translate: true, translatePrefix: ''}
      ),
      new DataControl(
        'centralLogistics',
        InputType.BOOLEAN,
        new FormControl(),
        {}
      ),
      new DataControl(
        'zipCode',
        InputType.TEXT,
        new FormControl(),
        {}
      ),
      new DataControl(
        'note',
        InputType.AREA,
        new FormControl(),
        {}
      )
    ];
  }
}
