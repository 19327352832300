import {Inject, Injectable, Injector} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import {DistributorStatus} from './model/distributor-status';
import {DistributorStatusName} from './model/distributor-status-name';
import {InstancePageableService} from '../../../shared/instance.service';
import {HasId} from '../../../shared/model/interface/has-id';
import {EntityChangeEvent} from '../../../shared/model/entity-change-event';
import {map} from 'rxjs/internal/operators';
import {FormActionName} from '../../../shared/form/form-action-name';
import {MessageService} from '../../../shared/message.service';
import {FormAction} from '../../../shared/form/form-action';
import {EmailTimeEditComponent} from '../pos/email-time-edit/email-time-edit.component';
import {DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {TranslateService} from '@ngx-translate/core';
import {StatusEditComponent} from '../pos/status-edit/status-edit.component';

@Injectable()
export abstract class OrganizationService<T extends HasId> extends InstancePageableService<T> {

  protected translateService: TranslateService;
  protected dialogService: DialogService;
  public messageService: MessageService;

  public images = {'shopPictureFilePath': new Subject<any>()};
  public items = {
    'status': new Observable<any>(),
    'roles': new Observable<any>()
  };

  protected currentEditObject: T;

  public STATUS = [
    new DistributorStatus(DistributorStatusName.OPERATIVE),
    new DistributorStatus(DistributorStatusName.INOPERATIVE),
    new DistributorStatus(DistributorStatusName.RESTRICTED)
  ];

  public ROLES = [
    {id: 0, name: 'ADMIN'}
  ];

  constructor(injector: Injector,
              @Inject('APIEndpoint') protected apiEndPoint: string) {
    super(injector);
    this.translateService = injector.get(TranslateService);
    this.dialogService = injector.get(DialogService);
    this.messageService = injector.get(MessageService);
    this.items.status = Observable.of(this.STATUS);
    this.items.roles = Observable.of(this.ROLES);
  }

  api(): string {
    return this.apiEndPoint;
  }

  onChange(event: EntityChangeEvent, form: FormGroup) {
    switch (event.type) {
      case 'location':
        this.setFormValueFromGmap(form, event.object);
        break;
    }
  }

  // TODO import confirmation
  setFormValueFromGmap(form, place) {
    const patch = {};

    this.layout().form.forEach(field => {
      const propertyName = field.name;
      switch (propertyName) {
        case 'name':
        case 'shortName':
          patch[propertyName] = place.name;
          break;

        case 'phoneNumber':
          patch[propertyName] = place.international_phone_number || place.formatted_phone_number;
          break;

        case 'businessHours':
          // TODO structure
          if (place.opening_hours && place.opening_hours.weekday_text) {
            patch[propertyName] = place.opening_hours.weekday_text.join('\n').trim();
          }
          break;

        case 'description':
          patch[propertyName] = place.website;
          break;

        case 'shopPictureFilePath':
          // TODO prepare urls source
          if (place.photos && place.photos.length > 0) {
            this.images[propertyName].next(place.photos);
            patch[propertyName] = place.photos[0].getUrl({maxWidth: 640});
          }
          break;
      }
    });

    if (Object.keys(patch).length > 0) {
      form.patchValue(patch);
    }
  }

  setCurrentEditObject(object) {
    this.currentEditObject = object;
  }

  abstract getRoleItemRole(userRoleItem);

  abstract setRoleItemRole(userRoleItem, role);

  abstract getCurrentDistributor();

  abstract saveRoleItems(saveRoleItems);

  abstract getRoleItems();

  abstract getApiPrefix();

  listActions(model) {
    const actions = super.listActions(model);
    actions.push(new FormAction({name: FormActionName.EMAIL_TIME_EDIT, key: 'entity.pos.email.time.edit'}));


    if (this.isSuperAdmin()) {
      actions.push(new FormAction({name: FormActionName.STATUS_CHANGE, key: 'entity.action.status'}));
    }

    return actions;
  }

  doAction(action, object) {
    const actionResult = super.doAction(action, object);

    switch (action.name) {
      case FormActionName.ADMINS:
        return this.onAdminEdit(action, object);

      case FormActionName.SAVE_ROLES:
        return this.onAdminsSave(action, object);

      case FormActionName.EMAIL_TIME_EDIT:
        return this.onEmailTimeEdit(action, object);

      case FormActionName.STATUS_CHANGE:
        return this.onStatusChange(action, object);
    }

    return actionResult;
  }

  onStatusChange(action, object) {
    return new Observable(observer => {
      const statusEditDialog: DialogRef = this.dialogService.open({
        title: this.translateService.instant('entity.action.status'),
        content: StatusEditComponent,
        width: 250
      });

      const inputs = statusEditDialog.content.instance;
      inputs.available = [
        DistributorStatusName.OPERATIVE,
        DistributorStatusName.INOPERATIVE,
        DistributorStatusName.RESTRICTED
      ];
      inputs.status = object.status;

      statusEditDialog.result.subscribe((result: any) => {
        if (result.text === 'Submit') {
          this.http.put(`${this.getApiPrefix()}${object.id}/${this.STATUS_ACTION_MAP[inputs.status.id]}`, null)
            .subscribe(() => observer.next(), () => observer.next());
        } else {
          observer.error();
        }
      });
    });
  }

  getImages(propertyName: string): any {
    if (this.images.hasOwnProperty(propertyName)) {
      return this.images[propertyName];
    }

    return null;
  }

  getItems(propertyName: string): any {
    if (this.items.hasOwnProperty(propertyName)) {
      return this.items[propertyName];
    }

    if (propertyName === 'users') {
      return this.http.get(this.apiPrefix + 'distributor/' + this.getCurrentDistributor().id + '/user')
        .pipe(map((distributorUsers: any[]) => {
          const users = [];
          distributorUsers.forEach(distributorUser => {
            users.push(distributorUser.user);
          });
          return users;
        }));
    }

    return null;
  }

  createUser(userDTO): Observable<Object> {
    const request = this.request(userDTO);
    return this.http.post('/api/distributor/' + this.getCurrentDistributor().id + '/user', request);
  }

  private onEmailTimeEdit(action: FormAction, object: any) {
    const actionSchedule = this.http.get(
      this.getApiPrefix() + object.id + '/schedule');

    actionSchedule.subscribe((response: any) => {
      const emailTimeEditDialog: DialogRef = this.dialogService.open({
        title: this.translateService.instant('email.time.edit'),
        content: EmailTimeEditComponent,
        width: 250
      });

      const emailTime = emailTimeEditDialog.content.instance;
      emailTime.items =
        response.schedule
          .map(s => {
            return {value: s ? new Date(s) : null};
          });

      emailTimeEditDialog.result.subscribe((result: any) => {
        if (result.text === 'Submit') {
          const request = emailTime.items.map(i => i.value);
          this.http.put(this.getApiPrefix() + object.id + '/schedule', {schedule: request})
            .subscribe(() => {
              this.messageService.success();
            });
        }
      });
    });
    return null;
  }

  private onAdminEdit(action: FormAction, object: any) {
    // TODO: refactoring: move to component logic
    this.setCurrentEditObject(object);
    this.getRoleItems()
      .pipe(map((userRoleItems1: any[]) => {
        userRoleItems1.forEach(userRoleItem => {
          userRoleItem.vendorRole = this.getRoleItemRole(userRoleItem);
        });
        return userRoleItems1;
      }))
      .subscribe((userRoleItems2: any[]) => {
        const filteredUserRoleItems = userRoleItems2.filter((userRoleItem) => userRoleItem.vendorRole !== null);
        this.entityEdit.emit({
          object: {userRole: filteredUserRoleItems},
          action: action
        });
      });
    return null;
  }

  private onAdminsSave(action: FormAction, object: any) {
    const userRoleItems = object.userRole;
    userRoleItems.forEach(userRoleItem => {
      delete userRoleItem.id;
      this.setRoleItemRole(userRoleItem, userRoleItem.vendorRole);
    });

    this.saveRoleItems(userRoleItems.filter(userRoleItem => {
      return userRoleItem.user.id !== null;
    }));
    return null;
  }
}
