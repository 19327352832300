import {EntityStatus} from '../../../../shared/model/interface/entity-status';
import {StatusView} from '../../../../shared/model/interface/status-view';
import {DistributorStatusName} from './distributor-status-name';

export class DistributorStatus extends EntityStatus {

  public id: string;
  public name: DistributorStatusName;
  public view: StatusView;

  constructor(name: DistributorStatusName) {
    super();
    this.id = name;
    this.name = name;
    switch (name) {
      case DistributorStatusName.OPERATIVE:
        this.view = new StatusView('check', 'k-notification k-notification-success');
        break;
      case DistributorStatusName.INOPERATIVE:
        this.view = new StatusView('warning', 'k-notification k-notification-closed');
        break;
      case DistributorStatusName.RESTRICTED:
        this.view = new StatusView('warning', 'k-notification k-notification-warning');
        break;
    }
  }

  getId() {
    return this.id;
  }

  getView() {
    return this.view;
  }
}
