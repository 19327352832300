import {EntityStatus} from '../../../../shared/model/interface/entity-status';
import {OrderStatusName} from './order-status-name';
import {StatusView} from '../../../../shared/model/interface/status-view';

export class OrderStatus extends EntityStatus {

  public id: string;
  public name: OrderStatusName;
  public view: StatusView;

  constructor(name: OrderStatusName) {
    super();
    this.id = name;
    this.name = name;

    switch (name) {
      case OrderStatusName.SUBMITTED:
        this.view = new StatusView('warning', 'k-notification k-notification-warning');
        break;
      case OrderStatusName.CANCELED:
        this.view = new StatusView('close', 'k-notification k-notification-closed');
        break;
      case OrderStatusName.CLOSED:
        this.view = new StatusView('check', 'k-notification k-notification-closed');
        break;
    }

  }

  getId() {
    return this.id;
  }

  getView() {
    return this.view;
  }
}
