import {Location} from '../../../../shared/model/location';
import {PosBase} from './pos-base';
import {Pos} from './pos';

export class PosDTO extends PosBase {
  public locations?: Location[];
  public status?: string | number;

  constructor(init?: Partial<Pos>) {
    super(init);
  }
}

