import {Component, Inject, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogContentBase, DialogRef} from '@progress/kendo-angular-dialog';
import {HttpClient} from '@angular/common/http';
import {ServerMessage} from '../../../../shared/model/message/server-message';
import {MessageService} from '../../../../shared/message.service';

@Component({
  selector: 'set-password',
  template: `
    <kendo-dialog-titlebar>
      <span translate="entity.user.password.set"></span>
    </kendo-dialog-titlebar>
    <div class="k-content">
      <form [formGroup]="formGroup">
        <div class="form-group">
          <label for="name"
                 translate="entity.user.password"></label>
          <input type="password"
                 class="k-textbox"
                 id="password"
                 formControlName="password"
                 required>
          <div class="form-tooltip"
               [hidden]="formGroup.controls['password'].valid || formGroup.controls['password'].pristine">
            <div class="k-state-error"
                 *ngIf="formGroup.controls['password'].errors !== null">
              <span *ngFor="let errorKey of keys(formGroup.controls['password'].errors)">{{errorKey | translate}}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <kendo-dialog-actions>
      <button kendoButton
              (click)="onConfirmAction()"
              [primary]="true"
              [disabled]="!formGroup.valid"
              translate="entity.user.password.set">
      </button>
      <button kendoButton
              (click)="onCancelAction()"
              translate="entity.action.cancel">Cancel
      </button>
    </kendo-dialog-actions>
  `
})
export class SetPasswordComponent extends DialogContentBase {
  keys = Object.keys;
  password: '';
  @Input() public userId: string;

  constructor(public dialog: DialogRef,
              @Inject('APIEndpoint') protected apiEndPoint: string,
              protected http: HttpClient,
              private messageService: MessageService) {
    super(dialog);
  }

  public formGroup: FormGroup = new FormGroup({
    'password': new FormControl(this.password,
      Validators.compose([
          Validators.required,
          Validators.minLength(6)
        ]
      ))
  });

  public onCancelAction(): void {
    this.dialog.close();
  }

  public onConfirmAction(): void {
    this.http.put(`api/${this.apiEndPoint}/${this.userId}/password`,
      {password: this.formGroup.controls['password'].value})
      .subscribe(() => {
        this.dialog.close();
        this.messageService.success();
      });
  }
}
