export class Filter {
  name: string;
  title: string;
  active: boolean;

  constructor(name, title, active) {
    this.name = name;
    this.title = title;
    this.active = active;
  }
}
