import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {EntityService} from '../../module/user/entity.service';

@Component({
  selector: 'image-url',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ImageComponent),
      multi: true,
    }]
})
export class ImageComponent implements ControlValueAccessor, Validator, OnInit {
  private parseError: boolean;
  public imageObject: any = {url: '', temp: true};
  public isErrorLoading = true;

  imagesList = [];

  @Input()
  public name: string;

  @Input()
  public readonly: boolean;

  @Input()
  public translatePrefix = '';

  @ViewChild('image') imageElement: ElementRef;
  @ViewChild('imageWrapper') imageWrapperElement: ElementRef;

  @Output() placeChange: EventEmitter<any> = new EventEmitter();

  constructor(private zone: NgZone, private renderer: Renderer2, private entityService: EntityService) {

  }

  ngOnInit() {
    const images = this.entityService.getImages(this.name)
    if (images !== null) {
      images.subscribe((imagesList) => {
        this.imagesList = [];
        imagesList.forEach((image) => {
          this.imagesList.push(image.getUrl({maxWidth: 640}));
        });
      });
    }
  };

  // this is the initial value set to the component
  public writeValue(obj: any) {
    this.imageObject.url = obj;

    this.zone.run(() => this.loadImage());
    // this.showImage();
  }

  onChange() {
    this.loadImage();
  }

  // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  // validates the form, returns null when valid else the validation object
  // in this case we're checking if the json parsing has passed or failed from the onChange method
  public validate(c: FormControl) {
    return (!this.parseError) ? null : {
      imageError: {
        valid: false,
      },
    };
  }

  // the method set in registerOnChange to emit changes back to the form
  private propagateChange(data) {
    return data;
  }

  private loadImage() {
    this.propagateChange(this.imageObject.url);
    this.renderer.setStyle(this.imageElement.nativeElement, 'display', 'none');
    this.renderer.setProperty(this.imageWrapperElement.nativeElement, 'innerHTML', '');

    if (this.imageObject.url === null) {
      return;
    }

    const imageObj = new Image();

    imageObj.addEventListener('load', () => this.zone.run(() => this.showImage()), false);
    imageObj.addEventListener('error', () => this.zone.run(() => this.showError()), false);

    imageObj.src = this.imageObject.url;

    this.renderer.appendChild(this.imageWrapperElement.nativeElement, imageObj);
  }

  private showImage() {
    this.isErrorLoading = false;
    const imageObj = new Image();
    imageObj.src = this.imageObject.url;

    this.renderer.setProperty(this.imageElement.nativeElement, 'innerHTML', '');
    this.renderer.appendChild(this.imageElement.nativeElement, imageObj);
    this.renderer.setStyle(this.imageElement.nativeElement, 'display', 'flex');
  }

  private showError() {
    this.renderer.setStyle(this.imageElement.nativeElement, 'display', 'none');
    this.isErrorLoading = true;
  }

  selectUrl(imageUrl: any) {
    this.imageObject.url = imageUrl;
    this.showImage();
    this.propagateChange(this.imageObject.url);
  }
}
