import {Cloneable} from '../interface/cloneable';

export class ServerMessage extends Cloneable<ServerMessage> {
  public className: string;
  public description: string;
  public message: string;
  public stackTrace: string;
  public translations: any;
  public errors: any;

  constructor(message?: Partial<ServerMessage>) {
    message.message = message.message ? message.message : (message.description ? message.description : 'error.unknown.server.error');
    super(message);
  }
}
