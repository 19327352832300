import {Component} from '@angular/core';
import {UserLogin} from '../shared/model/user-login';
import {Router} from '@angular/router';
import {AuthService} from '../shared/auth/auth.service';
import {LanguageService} from '../shared/language.service';
import {ServerMessage} from '../shared/model/message/server-message';
import {MessageService} from '../shared/message.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  user: UserLogin = new UserLogin('', '');

  constructor(public authService: AuthService,
              public router: Router,
              public language: LanguageService,
              public messageService: MessageService) {
  }

  login() {
    this.authService.login(this.user).subscribe(() => {
      this.language.fromPreference()
        .subscribe(() => {
          if (this.authService.isLoggedIn) {
            // Get the redirect URL from our auth service
            // If no redirect has been set, use the default
            const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';
            // Redirect the user
            this.router.navigate([redirect]);
          }
        });
    }, (errorResponse) => {
      this.messageService.error(new ServerMessage(errorResponse.error));
    });
  }
}
