export enum PermissionName {
  CREATE_BUYER = 'CREATE_BUYER',
  VIEW_DISTRIBUTOR = 'VIEW_DISTRIBUTOR',
  VIEW_POS = 'VIEW_POS',
  VIEW_USER = 'VIEW_USER',
  VIEW_ORDER = 'VIEW_ORDER',
  VIEW_TERMINAL = 'VIEW_TERMINAL',
  VIEW_DASHBOARD = 'VIEW_DASHBOARD',
  VIEW_VOUCHER = 'VIEW_VOUCHER',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
}
