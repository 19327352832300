import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthGuard} from '../../../shared/auth/auth-guard.service';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {GridModule} from '@progress/kendo-angular-grid';
import {DialogModule} from '@progress/kendo-angular-dialog';

import {ReactiveFormsModule} from '@angular/forms';
import {EntityService} from '../user/entity.service';
import {VoucherService} from './voucher.service';
import {EntityModule} from '../user/entity.module';
import {VoucherRoutingModule} from './voucher-routing.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonsModule,
    GridModule,
    DialogModule,
    EntityModule,
    VoucherRoutingModule
  ],
  declarations: [],
  providers: [
    AuthGuard,
    {provide: 'APIEndpoint', useValue: 'voucher'},
    {provide: EntityService, useClass: VoucherService},
    {provide: 'title', useValue: 'entity.voucher.title'}
  ]
})
export class VoucherModule {
}
