import {HasId} from '../../../../shared/model/interface/has-id';
import {Cloneable} from '../../../../shared/model/interface/cloneable';
import {OrderPos} from './order-pos';
import {OrderItem} from './order-item';

export class OrderBase extends Cloneable<OrderBase> implements HasId {
  public id: string;
  public pointOfSale: OrderPos;
  public orderItems: OrderItem[];
  public creationDate: Date;
  public dueDate: Date;
  public closingDate: Date;
  public description: string;
  public remark: string;

  constructor(init?: Partial<OrderBase>) {
    super(init);
  }

  public getId() {
    return this.id;
  }

}

