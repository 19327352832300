import {Location} from '../../../../shared/model/location';
import {DistributorStatus} from '../../distributor/model/distributor-status';
import {PosBase} from './pos-base';
import {HasStatus} from '../../../../shared/model/interface/has-status';

export class Pos extends PosBase implements HasStatus {

  public status: DistributorStatus;
  public location: Location;

  constructor(init?: Partial<Pos>) {
    super(init);
  }

  getStatus() {
    return this.status;
  }
}

