import {Component, OnInit} from '@angular/core';
import {OverlayService} from '../overlay.service';

@Component({
  selector: 'overlay',
  template: `
    <div class="overlay"
         [hidden]="!showOverlay"></div>
  `,
  styles: [`
    .overlay {
      opacity: 0.5;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
    }
  `]
})
export class OverlayComponent implements OnInit {

  showOverlay = false;

  constructor(private overlayService: OverlayService) {
  }

  ngOnInit() {
    this.overlayService.overlayChanged.subscribe((showOverlay) => {
      this.showOverlay = showOverlay;
    });
  }
}
