import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../shared/auth/auth-guard.service';
import {EntityListComponent} from '../../entity-list/entity-list.component';

const userRoutes: Routes = [
  {path: '', component: EntityListComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [
    RouterModule.forChild(userRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DistributorRoutingModule {
}
