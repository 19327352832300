import {PermissionName} from '../../shared/model/auth/role-name';

export class Card {
  name: string;
  title: string;
  description: string;
  icon: string;
  url: string;
  permissionName: PermissionName;
  public hasAccess: boolean;

  constructor(name, title, description, icon, url, permissionName) {
    this.name = name;
    this.title = title;
    this.description = description;
    this.icon = icon;
    this.url = url;
    this.permissionName = permissionName;
  }
}
