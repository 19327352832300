import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EntityListComponent} from '../../entity-list/entity-list.component';
import {EntityRoutingModule} from './entity-routing.module';
import {AuthGuard} from '../../../shared/auth/auth-guard.service';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {GridModule} from '@progress/kendo-angular-grid';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {EntityService} from './entity.service';
import {EntityEditFormComponent} from '../../entity-edit-form/entity-edit-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ImageComponent} from '../../component/image/image.component';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {CustomListComponent} from '../../component/list/list.component';
import {EntityFilterComponent} from '../../component/filter/entity-filter.component';
import {TranslateModule} from '@ngx-translate/core';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import {CustomDateComponent} from '../../component/date/date.component';
import {OrderPackageComponent} from '../../component/package/order-package.component';
import {StatusViewComponent} from '../../component/status/status-view.component';
import {OrganizationRolesComponent} from '../../component/organization-roles/organization-roles.component';
import {VoucherService} from '../voucher/voucher.service';
import {EntityStatisticComponent} from '../../component/statistic/entity-statistic.component';
import {TooltipModule} from '@progress/kendo-angular-tooltip';
import {SharedModule} from '../../../shared/component/shared.module';
import {LocationComponent} from '../../../shared/component/location/location.component';
import {TerminalDetailsComponent} from '../../component/terminal-details/terminal-details.component';
import {TerminalService} from '../terminal/terminal.service';
import {SetPasswordComponent} from './set-password/set-password.component';
import {CldrIntlService, IntlService} from '@progress/kendo-angular-intl';
import {TerminalErrorsComponent} from '../../component/terminal-errors/terminal-errors.component';
import {TerminalStatusComponent} from '../../component/terminal-status/terminal-status.component';
import {PopupModule} from '@progress/kendo-angular-popup';
import {ClickOutsideDirective} from '../../../shared/component/click-outside';
import {EmailTimeEditComponent} from '../pos/email-time-edit/email-time-edit.component';
import {StatusEditComponent} from '../pos/status-edit/status-edit.component';
import {InputsModule} from '@progress/kendo-angular-inputs';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
    GridModule,
    DialogModule,
    EntityRoutingModule,
    DropDownsModule,
    DateInputsModule,
    TranslateModule,
    TooltipModule,
    SharedModule,
    PopupModule,
    InputsModule
  ],
  declarations: [
    EntityEditFormComponent,
    EntityListComponent,
    ImageComponent,
    CustomListComponent,
    CustomDateComponent,
    OrderPackageComponent,
    OrganizationRolesComponent,
    StatusViewComponent,
    EntityFilterComponent,
    EntityStatisticComponent,
    TerminalErrorsComponent,
    TerminalStatusComponent,
    LocationComponent,
    TerminalDetailsComponent,
    SetPasswordComponent,
    ClickOutsideDirective,
    EmailTimeEditComponent,
    StatusEditComponent
  ],
  entryComponents: [SetPasswordComponent, StatusEditComponent],
  providers: [
    VoucherService,
    EntityService,
    AuthGuard,
    {provide: 'APIEndpoint', useValue: 'users'},
    {provide: 'title', useValue: 'entity.user.title'},
    TerminalService,
    {provide: IntlService, useExisting: CldrIntlService}
  ]
})
export class EntityModule {
}
