import {HasId} from '../../../../shared/model/interface/has-id';
import {Cloneable} from '../../../../shared/model/interface/cloneable';

export class TerminalBase extends Cloneable<TerminalBase> implements HasId {
  public id: string;
  public identifier: number;
  public description: string;

  constructor(init?: Partial<TerminalBase>) {
    super(init);
  }

  public getId() {
    return this.id;
  }

}

