import {Component} from '@angular/core';

@Component({
  template: `
    <div class="not-found">
      <h2>Page not found</h2>
      <a routerLink="/">Home page</a>
    </div>

  `,
  styles: [`
    .not-found {
      text-align: center;
      padding: 20px;
    }

  `]
})
export class PageNotFoundComponent {
}
