import {NgModule} from '@angular/core';
import {LocationComponent} from './location/location.component';

@NgModule({
  declarations: [
    // LocationComponent,
  ],
  exports: [
    // LocationComponent
  ]
})
export class SharedModule {
}
