import {Component} from '@angular/core';

@Component({
  template: `
    <div class="not-available">
      <h2>Server is not available</h2>
      <a href="/">Retry</a>
    </div>

  `,
  styles: [`
    .not-available {
      text-align: center;
      padding: 20px;
    }

  `]
})
export class UnavailableComponent {
}
