import {Inject, Injectable, Injector} from '@angular/core';
import {InstancePageableService} from '../../../shared/instance.service';
import {FormControl} from '@angular/forms';
import {InputType} from '../../../shared/input-type';
import {DataControl} from '../../../shared/form/data-control';
import {Observable} from 'rxjs/Observable';
import {Order} from './model/order';
import {OrderStatus} from './model/order-status';
import {OrderDTO} from './model/order-dto';
import {Pos} from '../pos/model/pos';
import {ListDataControl} from '../../../shared/form/list-data-control';
import {OrderStatusName} from './model/order-status-name';
import {FormAction} from '../../../shared/form/form-action';
import {TranslateService} from '@ngx-translate/core';
import {FormActionName} from '../../../shared/form/form-action-name';
import {FilterData} from '../../../shared/form/filter-data';
import {PermissionName} from '../../../shared/model/auth/role-name';


@Injectable()
export class OrderService extends InstancePageableService<Order> {

  filterList: any;
  public items = {};
  EMPTY_POS = {id: null, name: 'filter.pos.empty'};

  orderStatus = [
    new OrderStatus(OrderStatusName.SUBMITTED),
    new OrderStatus(OrderStatusName.CLOSED),
    new OrderStatus(OrderStatusName.CANCELED)
  ];

  constructor(injector: Injector,
              @Inject('APIEndpoint') protected apiEndPoint: string,
              private translate: TranslateService) {
    super(injector);
    this.items['orderStatus'] = Observable.of(this.orderStatus);
    this.items['pointOfSale'] = this.http.get<Pos>(this.apiPrefix + 'pos?orders=true');
    this.items['orderItems'] = this.http.get<Pos>(this.apiPrefix + 'package-type');

    // TODO: move to the filter
    translate.get(this.EMPTY_POS.name).subscribe((name) => {
      this.EMPTY_POS.name = name;
    });

    this.filterList = [];
    this.filterList.push(
      new FilterData(FilterData.asPartial({
        type: 'boolean',
        value: false,
        key: 'showInactive',
        valueProperty: 'value',
        role: PermissionName.ROLE_SUPER_ADMIN
      })));

  }

  api(): string {
    return this.apiEndPoint;
  }

  getAddButtonTitle() {
    return 'entity.order.create.title';
  }

  convert(response: any): Order {
    const locations = response['locations'];
    response.location = locations.length > 0 ? locations[0] : null;
    delete response['locations'];
    response.orderStatus = new OrderStatus(response.orderStatus);
    return new Order(response);
  }

  request(item: Partial<Order>): any {
    const request = new OrderDTO(item);
    if (item.location !== null) {
      request.locations = [];
      request.locations.push(item.location);
    }
    request.orderStatus = item.orderStatus.id;
    return request;
  }

  filters() {
    this.mergeFilters(this.filterList, this.query);
    return this.filterList;
  }

  newInstance(data): Order {
    return data ? new Order(data) : new Order({
      orderItems: [],
      pointOfSale: this.EMPTY_POS,
      orderStatus: new OrderStatus(OrderStatusName.SUBMITTED),
      creationDate: null,
      dueDate: null
    });
  }

  protected layoutConfig() {
    const HIDE = {readOnly: true, visible: false};
    const layoutConfig = {
      closingDate: {
        EMPTY: HIDE,
        SUBMITTED: HIDE,
        CLOSED: {readOnly: true, visible: true},
        CANCELED: HIDE
      },

      creationDate: {
        EMPTY: HIDE,
        SUBMITTED: HIDE,
        CLOSED: {readOnly: true, visible: true},
        CANCELED: {readOnly: true, visible: true}
      },

      orderStatus: {
        EMPTY: {readOnly: true, visible: false, translate: true, translatePrefix: 'entity.status.'},
        SUBMITTED: {readOnly: true, visible: true, translate: true, translatePrefix: 'entity.status.'},
        CLOSED: {readOnly: true, visible: true, translate: true, translatePrefix: 'entity.status.'},
        CANCELED: {readOnly: true, visible: true, translate: true, translatePrefix: 'entity.status.'}
      }
    };

    return layoutConfig;
  }

  protected applyLayoutConfig(layout, order) {
    const layoutConfig = this.layoutConfig();

    const status = order ? order.orderStatus.id : 'EMPTY';
    layout.form.forEach((control) => {
      if (layoutConfig.hasOwnProperty(control.name)) {
        control.editConfig = layoutConfig[control.name][status];
      }
    });

    if (order && order.id) {
      layout.form.forEach((control) => {
        control.editConfig.readOnly = true;
      });
    }
  }

  layout(order: Order) {
    const layout = {
      translatePrefix: 'entity.order.',
      form: [
        new DataControl(
          'id',
          InputType.VIEW,
          new FormControl(),
          {show: false}
        ),
        new DataControl(
          'orderStatus',
          InputType.LIST,
          new FormControl(),
          {type: 'status', sortable: false}
        ),
        new ListDataControl(
          'pointOfSale',
          InputType.LIST,
          this.EMPTY_POS,
          new FormControl(),
          {type: 'object', viewProperty: 'name'},
          {required: true, readOnly: false, visible: true, translate: true, translatePrefix: ''}
        ),
        new DataControl(
          'location',
          InputType.LOCATION,
          new FormControl(),
          {show: false}
        ),
        new DataControl(
          'description',
          InputType.AREA,
          new FormControl(),
          {minWidth: '1400px'}
        ),
        new DataControl(
          'orderItems',
          InputType.ORDER_PACKAGE,
          new FormControl(),
          {type: 'order-items', sortable: false},
          {required: true}
        ),
        new DataControl(
          'creationDate',
          InputType.DATE,
          new FormControl(),
          {type: 'date', minWidth: '1200px'}
        ),
        new DataControl(
          'dueDate',
          InputType.DATE,
          new FormControl(),
          {type: 'date', minWidth: '920px'}
        ),
        new DataControl(
          'closingDate',
          InputType.DATE,
          new FormControl(),
          {type: 'date', minWidth: '1200px'}
        ),
        new DataControl(
          'remark',
          InputType.AREA,
          new FormControl(),
          {minWidth: '1400px'}
        )
      ]
    };

    this.applyLayoutConfig(layout, order);
    return layout;
  }

  actionConfig() {
    return {
      SUBMITTED: [
        new FormAction({name: FormActionName.ORDER_CLOSE, key: 'entity.order.action.close'}),
        new FormAction({name: FormActionName.ORDER_CANCEL, key: 'entity.order.action.cancel'})
      ],
      CLOSED: [],
      CANCELED: []
    };
  }

  editActions(order: Order, action: FormAction): FormAction[] {
    if (order && order.id) {
      const actionConfig = this.actionConfig();
      if (actionConfig.hasOwnProperty(order.orderStatus.id)) {
        return actionConfig[order.orderStatus.id];
      }

      return [];
    }

    return super.editActions(order, action);
  }

  doAction(action, object) {
    const actionResult = super.doAction(action, object);

    switch (action.name) {
      case FormActionName.ORDER_CANCEL:
        return this.http.post(this.apiPrefix + this.api() + '/' + object.id + '/cancel', null);
      case FormActionName.ORDER_CLOSE:
        return this.http.post(this.apiPrefix + this.api() + '/' + object.id + '/close', null);
    }

    return actionResult;
  }


  getItems(propertyName: string): any {
    if (this.items.hasOwnProperty(propertyName)) {
      return this.items[propertyName];
    }

    return null;
  }
}



