import {Location} from '../../../../shared/model/location';
import {Cloneable} from '../../../../shared/model/interface/cloneable';

export class PointOfSaleBillingDTO extends Cloneable<PointOfSaleBillingDTO> {

  pointOfSaleId: string;
  pointOfSale: any;
  pointOfSaleName: string;
  vatNumber: number;
  zipCode: string;
  pointOfSaleEmail: string;
  note: string;
  public locations: Location[];
  public location: Location;
  billingInvoiceType: any;
  billingSubscriptionType: any;
  marketingFeeType: any;
  cupPriceType: any;
  cupPrice: number;
  cupServiceType: any;
  cupServicePrice: number;

  constructor(init?: Partial<PointOfSaleBillingDTO>) {
    super(init);
    this.location = init.locations.length > 0 ? init.locations[0] : null;
    this.pointOfSaleId = init.pointOfSale.id;
  }

}
