import {Component, OnInit} from '@angular/core';
import {MessageService} from '../../message.service';

@Component({
  selector: 'message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss']
})
export class MessagePopupComponent implements OnInit {
  keys = Object.keys;
  messages = [];

  popupOpened = false;
  popupData: any = null;

  constructor(private messageService: MessageService) {
  }

  ngOnInit() {
    this.messageService.messageAdded.subscribe((message) => {
      const filtered = this.messages.filter(m => {
        return m.message === message.message;
      });

      filtered.forEach(m => {
        this.messages.splice(this.messages.indexOf(m), 1);
      });

      this.messages.push(message);
      setTimeout(() => {
        this.messages.splice(this.messages.indexOf(message), 1);
      }, 5000);
    });

    this.messageService.messagePopup.subscribe((data) => {
      this.popupData = data;
      this.popupOpened = true;
    });
  }

  close() {
    this.popupOpened = false;
  }

  removeMessage(index) {
    this.messages.splice(index, 1);
  }
}
