export class TerminalHealth {
  id: number;
  containers: TerminalHealthContainer[];
  errors: TerminalHealthError[];
  creationDate: Date;
}

class TerminalHealthContainer {
  name: string;
  filling: number;
}

class TerminalHealthError {
  device: string;
  code: string;
}
