import {Component, Input, OnInit} from '@angular/core';
import {DialogContentBase, DialogRef} from '@progress/kendo-angular-dialog';

@Component({
  selector: 'status-edit',
  templateUrl: './status-edit.component.html'
})
export class StatusEditComponent extends DialogContentBase implements OnInit {
  @Input() public available;
  @Input() public status;
  items: any[];

  constructor(dialog: DialogRef) {
    super(dialog);
  }

  ngOnInit() {
    this.items = this.available.map(i => {
      return {id: i, name: i};
    });
  }

  public onCancelAction(): void {
    this.dialog.close({text: 'Cancel'});
  }

  public onConfirmAction(): void {
    this.dialog.close({text: 'Submit', primary: true});
  }
}
