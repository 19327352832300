import {DataControl} from './data-control';

export class RoleListDataControl extends DataControl {

  public defaultValue: any;
  public max: number;

  constructor(name,
              input,
              max,
              defaultValue,
              control,
              tableConfig,
              editConfig = {readOnly: false, visible: true, translate: false, translatePrefix: ''}) {
    super(name, input, control, tableConfig, editConfig);
    this.defaultValue = defaultValue;
    this.max = max;
  }
}
