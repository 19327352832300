import {Cloneable} from '../../../../shared/model/interface/cloneable';
import {HasId} from '../../../../shared/model/interface/has-id';
import {VoucherStatus} from './voucher-status';

export class VoucherBase extends Cloneable<VoucherBase> implements HasId {
  public id: string;
  public number: string;
  public issueDate: Date;

  constructor(init?: Partial<VoucherBase>) {
    super(init);
  }

  public getId() {
    return this.id;
  }

}


